/* eslint-disable camelcase */
// vue set 
const base_url = '/' ; 
const path_root = 'https://oceanlighttech.com/api';

//const path_root = "http://oltbase.test";
const path_api 	= 'api';
const path_api_images = path_root+'/storage/images/';

const dev = {
  debugMode:true ,
};

const config = { 
  base_url,
  path_root,
  path_api,
  path_api_images,
};

const restApi = {
  pathRoot: path_root + '/' + path_api
};

export { config, dev , restApi };
