/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
// init oltConsole
export class oltUrl{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('oltUrl!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  // 記錄 之後 想要回來的 地方 ， 例如 登入後回到強制登入頁 
  locationUrl(store,urlObj){
    //console.log('oUrl locationUrl test :  ');
    store.dispatch('locationUrl',urlObj);
    //console.log(store.state);
  }

  cleanLocationUrl(store){
    store.dispatch('cleanLocationUrl');
    //console.log(store.state);
  }



  // 回傳 前幾次瀏覽記錄的 路徑 , -1 ~ -n 往上 N層 
  getClickHistory(store,key){

  }

  // 查詢位置在 瀏覽記錄容器陣列的那一個索引上
  // 回傳 索引位置
  indexOfClickHistory(store,url){
  }

  // 寫入到 瀏覽記錄容器陣列尾端
  pushClickHistory(store,url){
  }

  // 移除 瀏覽記錄容器陣列的最後一筆
  popClickHistory(store,url){
  }

  // 寫入到 瀏覽記錄容器陣列前端
  unshiftClickHistory(store,url){
  }

  // 移除瀏覽記錄容器陣列的第一筆  
  shiftClickHistory(store,url){
  }

  // 移除瀏覽記錄容器陣列 指定的索引位置及數量  
  // 容器，索引位置，數量
  spliceClickHistory(store,key,num){ 
  }


}