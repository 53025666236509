
<template>
  <p>
    <a
      class="btn btn-primary"
      data-bs-toggle="collapse"
      href="#collapseExample"
      role="button"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Link with href
    </a>
    <button
      class="btn btn-primary"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseExample"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Button with data-bs-target
    </button>
  </p>
  <div
    class="collapse"
    id="collapseExample"
  >
    <div class="card card-body">
      Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
    </div>
  </div>

  <div class="height-200 d-none d-md-block" />   
  <div class="height-200" />
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , Footer } from '@/components/Frame';

export default {
  name: 'App',
  components: {
    //SideNav,
    //Footer
  },
  setup() {

    const copyrightTime = ref() ;

    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 

    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      copyrightTime
    };
  },
  created() {
  },
  mounted() {
    window.location.hash = '#header';
  },
  methods: {
  }
};
</script>
