
<template>
  <!-- Navbar Light -->
  <SideNav />
  <!-- End Navbar -->

  <header>
    <div
      class="page-header section-height-75"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="container mt-8 mt-md-0">
        <div class="row">
          <div class="col-md-5   d-flex justify-content-center text-md-left text-center flex-column">
            <h2 class="nicole-text-title text-gradient text-info mb-0">
              Work With Our Team
            </h2>
            <h2 class="nicole-text-title mb-4">
              Evolution Continues
            </h2>
            <p class="nicole-text-name pe-md-5 me-md-5 mb-4">
              海煜科技成立於 2019 年，我們提供各種軟體、硬體、網路、IOT互聯網、資安等資訊整合服務，至目前為止我們的主要客戶有上市櫃教育集團跟教育相關政府公部門，團隊累積累積多年的資訊整合服經驗。
            </p>
          </div>
          <div class="col-12 col-md-7 px-0">
            <img
              src="@/assets/img/illustration.jpeg"
              class="img-fluid"
            >
          </div>
        </div>
      </div>
    </div>
  </header>
       
    




  <section
    class="py-6"
    data-aos="zoom-in"
    data-aos-duration="600"
    data-aos-once="ture"  
    id="Philosophy"
  >
    <div class="container">
      <div class="row pt-5 pt-md-4 pb-md-6">
        <div class="col-md-8 mx-auto text-center">
          <h6 class="nicole-text-name text-gradient text-info text-uppercase">
            Service Features
          </h6>
          <h2 class="nicole-text-title">
            服務特色
          </h2>
        </div>
        <div class="col-12 mt-2">
          <h5
            data-aos="zoom-in"
            data-aos-delay="300"
            data-aos-duration="500"
            class="pt-3 text-gray text-left"
          >
            <h6>
              1. 用科技引領生活的改變
            </h6>
            <h6>
              2. 積極與各領域單位合作研發品質更高、成本更低的產品
            </h6>
            <h6>
              3. 明定開發作業、生產管理及品質稽核的嚴格規範
            </h6>
            <h6>
              4. 一處開發、全載具可同時佈建;一團隊開發、全載具可同團隊維運
            </h6>
            <h6>
              5. 恪守三高一低（高品質、高效率、高擴展性、低成本）的研發精神
            </h6>
            <h6>
              6. 研發可高速、批量生產軟體的開發引擎，並建立政策標準化之作業生產流水線
            </h6>

            <!--
            一處開發、全載具可同時佈建、一團隊開發、全載具可同團隊維運。提供客戶三高一低（高品質、高效率、高擴展性、低成本）之資訊整合服務。
          </h5>
          <h5
            data-aos="zoom-in"
            data-aos-delay="300"
            data-aos-duration="500"
            data-aos-once="ture"
            class="pt-3 text-gray text-left"
          >
            我們使用標準化的生產作業政策流程和標準化開發框架僅需同一個團隊在同一處開發即可同時佈建於全載具（手機、平板、智慧電視、電腦、MAC、網頁）並也僅需同一團隊即可維運全載具服務，延續高擴展性、高可重覆利用性、高穩定性與高安全性架構設計理念不停升級與設計最新最有效率之軟硬體架構提供客戶高品質與高效率資訊整合服務並可在全載具雲端應用佈建需求下提供最低的軟體開發、系統建置與維運人力成本及解決方案。
            例如：
            一個資訊雲端應用平台需同時在手機、平板、網頁、電腦等載具上服務，我們使用了標準化的生產政策與開發框架，因為標準化所以可以有超高擴展性及可重覆利用性同時兼具了高穩定性和高安全性可降低了客戶三到五倍的建置維運成本，同時也大幅度的降低了建置的風險和時程，大幅度的增加客戶市場競爭力。
          </h5>
      -->
          </h5>
        </div>
      </div>
    </div>
  </section>


  <section
    class="pt-5 py-6"
    data-aos="zoom-in"
    data-aos-duration="600"
    data-aos-once="ture"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <h5 class="nicole-text-name text-gradient text-info text-uppercase">
            容器化微服務架構
          </h5>
          <h3 class="nicole-text-title">
            Containerizations , Microservices Architecture
          </h3>
          <h6 class="py-2">
            昀揚科技多年以來堅持，所有應用服務與模組皆全面採用容器化微服務架構，使系統具備高度的整合擴展性，在各種複雜的 IT 使用情境和整合需求下有著最活潑的解決方案。
          </h6>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 py-4">
          <div class="shadow border-radius-lg">
            <a class="d-block"><img
              src="@/assets/img/workpro.jpg"
              class="img-fluid border-radius-lg"
            ></a>
          </div>
        </div><div class="col-lg-6 text-left mt-4">
          <h6>
            1. 容器化微服務架構（Containerizations , Microservices Architecture）
          </h6>
          <h6>
            面對 Infra 端的管理採用 Kubernetes ( K8S ) 來管理微服務 ( Microservices ) ，可輕鬆並且高效的部署 ( Deploy )容器（Container）與各種服務（Services）
            系統架構高度的可靠性（Reliability）、可用性（Availability）、可維護性（Serviceability）大幅度的節省部署與維運成本。
          </h6>
          <h6>
            2. 整合元件（Integrated Components）
          </h6>
          <h6>
            全面採用 RESTful API 風格架構，支援 Postman API，提供跨載具SDK（利用本產品您可以輕鬆整合您的服務在各種載具上），功能架構的一致性提供了系統高度的擴展性和可重覆利用性，大幅度節省開發與整合成本。
          </h6>
          <h6>
            3. 資訊安全（Information Security）
          </h6>
          <h6>
            遵守 OWASP Top10 等各種資安規範開發。
          </h6>
        </div>
      </div>
    </div>
  </section>
        
  <!--
  <section
    class="pt-5"
    data-aos="zoom-in"
    data-aos-duration="600"
    data-aos-once="ture"
  >
    <div class="container"> 
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <h6 class="nicole-text-name text-gradient text-info text-uppercase">
            The Executive Team
          </h6>
          <h2 class="nicole-text-title">
            Building the future
          </h2>
        </div>
      </div>
      <div class="row mt-md-5 mt-4">
        <div class="col-lg-3 col-12 mb-lg-0 mb-4">
          <div class="card shadow-xl move-on-hover">
            <div class="card-body text-center bg-white border-radius-lg p-md-3 ">
              <a href="javascript:;">
                <img
                  class="w-100 border-radius-md"
                  src="@/assets/img/img001.jpg"
                  style="height:300px; object-fit: cover;"
                >
              </a>
              <h5 class="mt-3 mb-1 d-md-block d-none">
                新北市選書師記者會
              </h5>
              <p class="mt-3 mb-0 text-xs font-weight-bolder text-info text-gradient text-uppercase">
                2020
              </p>
            </div>
          </div>
        </div>  
        <div class="col-lg-3 col-12 mb-lg-0 mb-4">
          <div class="card shadow-xl move-on-hover">
            <div class="card-body text-center bg-white border-radius-lg p-md-3">
              <a href="javascript:;">
                <img
                  class="w-100 border-radius-md"
                  src="@/assets/img/img005.jpg"
                  style="height:300px; object-fit: cover;"
                >
              </a>
              <h5 class="mt-3 mb-1 d-md-block d-none">
                團隊聚餐
              </h5>
              <p class="mt-3 mb-0 text-xs font-weight-bolder text-info text-gradient text-uppercase">
                2019
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-12 mb-lg-0 mb-4">
          <div class="card shadow-xl move-on-hover">
            <div class="card-body text-center bg-white border-radius-lg p-md-3">
              <a href="javascript:;">
                <img
                  class="w-100 border-radius-md"
                  src="@/assets/img/img006.jpg"
                  style="height:300px; object-fit: cover;"
                >
              </a>
              <h5 class="mt-3 mb-1 d-md-block d-none">
                尾牙聚餐
              </h5>

              <p class="mt-3 mb-0 text-xs font-weight-bolder text-info text-gradient text-uppercase">
                2020
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-12 mb-lg-0 mb-4">
          <div class="card shadow-xl move-on-hover">
            <div class="card-body text-center bg-white border-radius-lg p-md-3">
              <a href="javascript:;">
                <img
                  class="w-100 border-radius-md"
                  src="@/assets/img/img004.jpg"
                  style="height:300px; object-fit: cover;"
                >
              </a>
              <h5 class="mt-3 mb-1 d-md-block d-none">
                網路資安技術研討會
              </h5>
              <p class="mt-3 mb-0 text-xs font-weight-bolder text-info text-gradient text-uppercase">
                2020
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  -->
  <section
    class="pt-5 py-lg-4"
    data-aos="zoom-in"
    data-aos-duration="600"
    data-aos-once="ture"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-5 ms-auto me-auto text-center">
          <div class="p-3 info-hover-warning" />
          <h3 class="text-gradient text-primary mb-0 mt-4">
            我們的優勢
          </h3>
          <h3>OUR Advantage</h3>
          <!--
          <p>Cloud Rasie Resources Access Optimization ( RAO ) 技術</p>
          -->
        </div>
      </div>
      <div class="row mt-5">
        <!--
        <div class="col-md-4 ms-auto my-auto">
          <a>
            <div
              class="card card-background tilt"
              data-tilt=""
            >
              <div
                class="full-background rounded-3"
                style="background-image: url(assets/img/systest.jpg)"
              ><span class="mask bg-gradient-dark opacity-3 rounded-3" /></div>
              <div class="card-body pt-8 text-center">
                <div class="icon icon-lg up mb-3">
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="0 0 40 40"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>chart-pie-35</title>
                    <g
                      id="Basic-Elements"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Rounded-Icons"
                        transform="translate(-1720.000000, -742.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g
                          id="Icons-with-opacity"
                          transform="translate(1716.000000, 291.000000)"
                        >
                          <g
                            id="chart-pie-35"
                            transform="translate(4.000000, 451.000000)"
                          >
                            <path
                              d="M21.6666667,18.3333333 L39.915,18.3333333 C39.11,8.635 31.365,0.89 21.6666667,0.085 L21.6666667,18.3333333 Z"
                              id="Path"
                              opacity="0.602864583"
                            />
                            <path
                              d="M20.69,21.6666667 L7.09833333,35.2583333 C10.585,38.21 15.085,40 20,40 C30.465,40 39.0633333,31.915 39.915,21.6666667 L20.69,21.6666667 Z"
                              id="Path"
                            />
                            <path
                              d="M18.3333333,19.31 L18.3333333,0.085 C8.085,0.936666667 0,9.535 0,20 C0,24.915 1.79,29.415 4.74166667,32.9016667 L18.3333333,19.31 Z"
                              id="Path"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <h1 class="text-white up mb-0">Search and Discover!</h1>
                <p class="lead up">開發經驗</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-5 me-auto my-auto ms-md-5">
          <div class="p-3 info-horizontal d-flex">
            <div>
              <h5>1. 風格架構</h5>
              <p>
                採用 REST API 風格架構及標準化協定，使系統具備跨載具跨平台整合性，實現企業服務雲端化之建置。
              </p>
            </div>
          </div>

          <div class="p-3 info-horizontal d-flex">
            <div>
              <h5>2. 敏捷式開發</h5>
              <p>
                視專案情況嚴謹的定義任務執行所需之完成度及品質之標準，使用敏捷式開發法管理專案。
              </p>
            </div>
          </div>

          <div class="p-3 info-horizontal d-flex">
            <div>
              <h5>3. 精準追踪管理</h5>
              <p>
                導入雲端化問題追踪管理系統（Issue Tracker Sysstem），精準且密集的追踪專案間每一個問題的狀態、重要性、完成度、解決方案。
              </p>
            </div>
          </div>
        </div>
      </div>
      -->
        <div class="col-md-4 ms-auto">
          <a>
            <div
              class="card card-background tilt"
              data-tilt=""
            >
              <div
                class="full-background rounded-3"
                style="background-image: url(assets/img/systest.jpg)"
              ><span class="mask bg-gradient-dark opacity-3 rounded-3" /></div>
              <div class="card-body pt-8 text-center">
                <div class="icon icon-lg up mb-3">
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="0 0 40 40"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>chart-pie-35</title>
                    <g
                      id="Basic-Elements"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Rounded-Icons"
                        transform="translate(-1720.000000, -742.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g
                          id="Icons-with-opacity"
                          transform="translate(1716.000000, 291.000000)"
                        >
                          <g
                            id="chart-pie-35"
                            transform="translate(4.000000, 451.000000)"
                          >
                            <path
                              d="M21.6666667,18.3333333 L39.915,18.3333333 C39.11,8.635 31.365,0.89 21.6666667,0.085 L21.6666667,18.3333333 Z"
                              id="Path"
                              opacity="0.602864583"
                            />
                            <path
                              d="M20.69,21.6666667 L7.09833333,35.2583333 C10.585,38.21 15.085,40 20,40 C30.465,40 39.0633333,31.915 39.915,21.6666667 L20.69,21.6666667 Z"
                              id="Path"
                            />
                            <path
                              d="M18.3333333,19.31 L18.3333333,0.085 C8.085,0.936666667 0,9.535 0,20 C0,24.915 1.79,29.415 4.74166667,32.9016667 L18.3333333,19.31 Z"
                              id="Path"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <h1 class="text-white up mb-0">RAO 開發技術
                </h1>
                <!--
                <p class="lead up">RAO 開發技術</p>
                -->
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-5 me-auto my-auto ms-md-5">
          <div class="p-3 info-horizontal d-flex">
            <div>
              <h5>Cloud Rasie Resources Access Optimization RAO 技術</h5>
              <p>
                昀揚科技多年與上市櫃公司及政府機構合作經驗研發出 RAO 技術， 改善大型網路應用平台在環境嚴苛且複雜的資料邏輯及百萬級別高壓存取環境的異步、延遲、封包遺失等問題，透過各種演算法大幅降低資料在傳輸層與應用層相依及遞移的資料錯誤問題，利用壓縮、快取、優化、封包壓縮將每一個存取反應控制在極低的時間內。
                即便在面臨數百萬筆 Session 的演算時，RAO技術具備高一致性有效降低開發週期與成本並大幅度提高生產品質，讓生產人員有著標準化及可自動化檢查的整合介面，提供客戶更加安全、可靠、穩定、高效、高可併發的雲端運算及應用服務。
              </p>
            </div>
          </div>
        </div>


        <hr class="dark my-4 my-md-6 mx-md-7">
        <div class="row">
          <div class="col-md-5 ms-auto my-auto">
            <div class="p-3 info-horizontal d-flex">
              <div>
                <h5>1. 嚴格品質控管</h5>
                <p>
                  成立品質稽核部門，規範工程移交文件(HandOver Document)採取Daily Build 及 Auto Build 稽核品質及效能，達到高品質及高效能資訊服務之應用整合。
                </p>
              </div>
            </div>

            <div class="p-3 info-horizontal d-flex">
              <div>
                <h5>2. Scrum Process</h5>
                <p>
                  利用Scrum Process 每日追蹤管理專案任務之狀態及進度，並且透明化所有專案成員之任務執行狀態，配合 Issue Tracker System 精準溝通及追蹤專案，降低溝通成本以達到精準溝通及整合效率。
                </p>
              </div>
            </div>

            <div class="p-3 info-horizontal d-flex">
              <div>
                <h5>3. 多層架構開發</h5>
                <p>
                  系統架構採多層架構開發，層層撥離、層層負責的開發架構，使工程團隊各司其職，讓系統模組具備高一致性、延展性和可重覆利用性，藉此達到系統模組化，多人快速分工標準化作業流程及政策整合以完成各種大型複雜專案。
                </p>
              </div>
            </div>
          </div>


          <div class="col-md-4 me-auto my-auto ms-md-5">
            <a 
              href="javascript:;"
              @click="goToPage('/ContactUs')"            
            >
              <div
                class="card card-background tilt"
                data-tilt=""
              >
                <div
                  class="full-background rounded-3"
                  style="background-image: url(assets/img/unitest1.jpg)"
                ><span class="mask bg-gradient-dark opacity-3 rounded-3" /></div>
                <div class="card-body pt-10 text-center">
                  <div class="icon icon-lg up mb-3">
                    <svg
                      width="40px"
                      height="40px"
                      viewBox="0 0 46 42"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <title>customer-support</title>
                      <g
                        id="Basic-Elements"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Rounded-Icons"
                          transform="translate(-1717.000000, -291.000000)"
                          fill="#FFFFFF"
                          fill-rule="nonzero"
                        >
                          <g
                            id="Icons-with-opacity"
                            transform="translate(1716.000000, 291.000000)"
                          >
                            <g
                              id="customer-support"
                              transform="translate(1.000000, 0.000000)"
                            >
                              <path
                                d="M45,0 L26,0 C25.447,0 25,0.447 25,1 L25,20 C25,20.379 25.214,20.725 25.553,20.895 C25.694,20.965 25.848,21 26,21 C26.212,21 26.424,20.933 26.6,20.8 L34.333,15 L45,15 C45.553,15 46,14.553 46,14 L46,1 C46,0.447 45.553,0 45,0 Z"
                                id="Path"
                                opacity="0.59858631"
                              />
                              <path
                                d="M22.883,32.86 C20.761,32.012 17.324,31 13,31 C8.676,31 5.239,32.012 3.116,32.86 C1.224,33.619 0,35.438 0,37.494 L0,41 C0,41.553 0.447,42 1,42 L25,42 C25.553,42 26,41.553 26,41 L26,37.494 C26,35.438 24.776,33.619 22.883,32.86 Z"
                                id="Path"
                              />
                              <path
                                d="M13,28 C17.432,28 21,22.529 21,18 C21,13.589 17.411,10 13,10 C8.589,10 5,13.589 5,18 C5,22.529 8.568,28 13,28 Z"
                                id="Path"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <h1 class="text-white up mb-0">Talk and Meet!</h1>
                  <p class="lead up">讓我們了解你的專案</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
   
  <div class="space-180" /> 

  <Footer />
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , Footer } from '@/components/Frame';

export default {
  name: 'App',
  components: {
    SideNav,
    Footer
  },
  setup() {

    const copyrightTime = ref() ;

    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 

    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      copyrightTime
    };
  },
  created() {
  },
  mounted() {
    this.$smoothScroll({
      scrollTo: document.getElementById('header'),
      //hash: '#header' // required if updateHistory is true
    });
  },
  methods: {
    goToPage(page){
      console.log(page);
      this.$router.push( { path: page } );
    },        
  }

};
</script>
