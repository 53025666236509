<template>
  <!-- Navbar -->
  <nav
    class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm">
            <a
              class="opacity-3 text-dark"
              href="javascript:;"
            >
              <svg
                width="12px"
                height="12px"
                class="mb-1"
                viewBox="0 0 45 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>shop </title>
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-1716.000000, -439.000000)"
                    fill="#252f40"
                    fill-rule="nonzero"
                  >
                    <g transform="translate(1716.000000, 291.000000)">
                      <g transform="translate(0.000000, 148.000000)">
                        <path d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z" />
                        <path d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item text-sm">
            <a
              class="opacity-5 text-dark"
              href="javascript:;"
            >
              系統管理
            </a>
          </li>
          <li
            class="breadcrumb-item text-sm text-dark active"
            aria-current="page"
          >
            身份管理
          </li>
        </ol>
        <!--
        <h6 class="font-weight-bolder mb-0">
          身份管理
        </h6>
        -->
      </nav>
      <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
        <a
          href="javascript:;"
          class="nav-link text-body p-0"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line" />
            <i class="sidenav-toggler-line" />
            <i class="sidenav-toggler-line" />
          </div>
        </a>
      </div>
      <div
        class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
        id="navbar"
      >
        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
        <!--
          <div class="input-group input-group-outline">
            <label class="form-label">Search here</label>
            <input
              type="text"
              class="form-control"
            >
          </div>
          -->
        </div>
        <ul class="navbar-nav  justify-content-end">
          <li class="nav-item">
            <a
              href="javascript:;"
              class="nav-link text-body p-0 position-relative"
              @click="goToPage('/AuthPage/SignIn')"
            >
              <i class="material-icons me-sm-1">
                logout
              </i>
            </a>
          </li>

          <li class="nav-item px-3">
            <a
              href="javascript:;"
              class="nav-link text-body p-0 position-relative"
              @click="goToPage('/AuthPage/SignIn')"
            >
              <i class="material-icons me-sm-1">
                lock
              </i>
            </a>
          </li>

          <li class="nav-item">
            <a
              href="javascript:;"
              class="nav-link text-body p-0 position-relative"
              @click="goToPage('/AuthPage/SignIn')"
            >
              <i class="material-icons me-sm-1">
                account_circle
              </i>
            </a>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="javascript:;"
              class="nav-link text-body p-0"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line" />
                <i class="sidenav-toggler-line" />
                <i class="sidenav-toggler-line" />
              </div>
            </a>
          </li>
          <li class="nav-item px-3">
            <a
              href="javascript:;"
              class="nav-link text-body p-0"
            >
              <i class="material-icons fixed-plugin-button-nav cursor-pointer">
                settings
              </i>
            </a>
          </li>
          <li class="nav-item dropdown pe-2">
            <a
              href="javascript:;"
              class="nav-link text-body p-0 position-relative"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="material-icons cursor-pointer">
                notifications
              </i>
              <span class="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                <span class="small">11</span>
                <span class="visually-hidden">unread notifications</span>
              </span>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end p-2 me-sm-n4"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                >
                  <div class="d-flex align-items-center py-1">
                    <span class="material-icons">email</span>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal my-auto">
                        Check new messages
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                >
                  <div class="d-flex align-items-center py-1">
                    <span class="material-icons">podcasts</span>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal my-auto">
                        Manage podcast session
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item border-radius-md"
                  href="javascript:;"
                >
                  <div class="d-flex align-items-center py-1">
                    <span class="material-icons">shopping_cart</span>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal my-auto">
                        Payment successfully completed
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import { oltConsole , oAC , oAuth , oUserInfo} from '@/lib/oltModule/initClient';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    /* eslint-disable vue/require-default-prop */
    showmode: String,
    tname: String,
    hiddenHeader:Boolean,
    routerBack:Boolean,
    routerName:String,
    routerTitle:String,
    rightbtnname: String,
    onright:Function,
    onleft:Function,
    rightbtnstatus: String,
    righttype: String
  },
  data () {
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';

    return { 
      //i18n
      t,
      locale,
      // params
      setDebugMode:false , // 該功能的除錯模式
      apiDebug:false , // API 除錯模式
      apiGetPageDebug:false , // API getPageRecord 除錯模式
      apiGetRtDebug:false , // API getRt 除錯模式
      apiGetOneDebug:false , // API getOneRecord 除錯模式
      apiPutDebug:false , // API 修改 除錯模式
      apiPostDebug:false , // API 新增 除錯模式
      apiDeleteDebug:false , // API 刪除 除錯模式
      platform:'client' ,    
      apiName:'user_role_priority' ,   
      apiParam:{
        uid:'',
      },
      conditionType:'pky',  
      pky:{'uid':'user_role_priority' }, 
      rtSet:{},
      record: [],
      totalPage:'',
      session: {},
      recordData: [],
      rtAllRecord:[],
      rtPageRecord:[],
      selectItems:{},
      perPageOptions: [5, 10, 25, 50 , 75 , 100],
      pageTab:0 ,
      mail_total:0,
      view_id:'',
      timer_id:'',
    };
  },
  created(){

  },
  mounted(){ 

  },
  methods:{
    goToPage(page){
      this.$router.push( { path: page } );
    },      
  },
};
</script>

