/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
// init oltConsole
export class oltUserInfo{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('oltUserInfo!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  // 記錄 之後 想要回來的 地方 ， 例如 登入後回到強制登入頁 
  setUserInfo(store,Obj){
    //console.log('oUrl locationUrl test :  ');
    store.dispatch('setUserInfo',Obj);
    //console.log(store.state);
  }

  cleanUserInfo(store){
    store.dispatch('cleanUserInfo');
    //console.log(store.state);
  }

  getUserInfo(store){
    if(store.getters.isUserInfo){
      return store.state.userInfo.userInfo;
    }
    return false ; 
  }

  setTimerHeaderViewId(store,id){
    store.dispatch('setTimerHeaderViewId',id);
  }

  getTimerHeaderViewId(store){
    return store.state.userInfo.timer.headerViewId;
  }

}