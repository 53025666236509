
<template>
  <SideNav />
  <header>
    <div class="page-header section-height-50 bg-gradient-dark d-none d-md-block" />
    <div
      class="bg-gradient-warning position-relative mx-xxl-8 mx-xl-4 mx-md-3 py-5 z-index-2 rounded-lg-3   mt-md-n11"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <img
        src="@/assets/img/shapes/waves-white.svg"
        alt="pattern-lines"
        class="position-absolute opacity-6 start-0 top-0 w-100"
      >
      <div class="container position-relative">
        <div class="row justify-content-center align-items-center mx-0">
          <div class=" end-0 bottom-0 mt-6 me-n4 z-index-2 col-7 col-md-8  d-md-none">
            <img
              class="img-fluid"
              src="@/assets/img/phones.png"
            >
          </div>  
          <div
            class="col-lg-6 col-md-8 text-left ps-md-5 py-sm-5 me-auto position-relative"
          >
            <div class="position-relative">
              <p class="nicole-text-name text-white mb-0">
                成功案例
              </p>
              <h2 class="nicole-text-title3 text-white text-bold">
                Successful Case
              </h2>
              <p class="nicole-text-name text-white">
                海煜科技累積多年資訊應用整合服務經驗，我們用心傾聽每一個客戶的需求， 根據整合經驗強化技術服務，為客戶提供最佳方案。
              </p>
              <div class="row justify-content-start mt-4">
                <div class="col-lg-3 col-4 pe-lg-0">
                  <!--
                  <a
                    href="javascript:;"
                    class="bg-gradient-dark border-radius-md py-1 px-1 d-flex"
                  >
		-->
                  <div class="bg-gradient-dark border-radius-md py-1 px-1 d-flex">
                    <img
                      class="w-100"
                      src="@/assets/img/logos/app-store-button.svg"
                    >
                  </div>
                  <!--
                  </a>
		-->
                </div>
                <div class="col-md-3 col-4 pe-md-0">
                  <!--
                  <a
                    href="javascript:;"
                    class="bg-gradient-dark border-radius-md py-1 px-1 d-flex"
                  >
		-->
                  <div class="bg-gradient-dark border-radius-md py-1 px-1 d-flex">
                    <img
                      class="w-100"
                      src="@/assets/img/logos/google-play-button.svg"
                    >
                  </div>
                  <!--
                  </a>
		-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-absolute end-0 bottom-0 mb-md-n7 mb-lg-n8 mb-xl-n6 mb-xxl-n8 me-n4 z-index-2 col-md-5 col-lg-4 col-xl-3 col-xxl-3  d-md-block d-none">
          <img
            class="w-100"
            src="@/assets/img/phones.png"
          >
        </div>
      </div>
    </div>
  </header>
  <div
    class="space-180 d-none d-lg-block"
    id="edu"
  />

  <div
    class="container mt-sm-5 mt-3"
  >
    <div
      class="row text-center my-sm-3 mt-5"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"   
    >
      <div class="col-lg-6 mx-auto">
        <h2 class="nicole-text-title text-info text-gradient mb-0">
          教育
        </h2>
        <h2 class="nicole-text-title">
          Education
        </h2> 
        <p class="nicole-text-name">
          題庫系統、直播教學、報表系統、使用者系統、金流系統、維運服務
        </p>
      </div>
    </div>



    <!--新北市政府 校舍管理系統-->
    <div
      class="row mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"   
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5  mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>新北市政府校舍管理系統</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              國小、國中、高中、高職 新北市三百所學校建置導入
            </li>
            <li class="pb-2">
              校務任務模組
            </li>
            <li class="pb-2">
              智慧園區模組
            </li>
            <li class="pb-2">
              校舍資產品管理模組
            </li>
            <li class="pb-2">
              園區地圖導覽模組
            </li>
            <li class="pb-2">
              物件屬性模組
            </li>
            <li class="pb-2">
              繪圖渲染輸出模組
            </li>
            <li class="pb-2">
              繪圖模組
            </li>
            <li class="pb-2">
              動態訊息模組
            </li>
            <li class="pb-2">
              SSO模組
            </li>
            <li class="pb-2">
              教育行政管理模組
            </li>
            <li class="pb-2">
              Rest API 開發、支援 Open API 3.0 採用 Postman 工具
            </li>
            <li class="pb-2">
              採用 Docker 架構
            </li>
            <li class="pb-2">
              系統與軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-3">
          <div class="col-12mt-sm-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/NTCCM/1.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">BMMS Platform 1.0</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>



    <!--新北市政府 智慧命題系統-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"   
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5  mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>新北市政府智慧命題系統</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              國小、國中、高中、高職 新北市三百所學校建置導入
            </li>
            <li class="pb-2">
              校務任務模組
            </li>
            <li class="pb-2">
              TronEdu授權
            </li>
            <li class="pb-2">
              CRStream授權
            </li>
            <li class="pb-2">
              智慧命題模組
            </li>
            <li class="pb-2">
              108課綱模組
            </li>
            <li class="pb-2">
              課程版本模組
            </li>
            <li class="pb-2">
              成績分析模組
            </li>
            <li class="pb-2">
              試卷分析模組
            </li>
            <li class="pb-2">
              SSO模組
            </li>
            <li class="pb-2">
              教育行政管理模組
            </li>
            <li class="pb-2">
              Rest API 開發、支援 Open API 3.0 採用 Postman 工具
            </li>
            <li class="pb-2">
              採用 Docker 架構
            </li>
            <li class="pb-2">
              系統與軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-3">
          <div class="col-sm-6 mt-sm-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/NTCED/5.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">出卷模組</h6>
              </div>
            </a>
          </div>
          <div class="col-sm-6 mt-sm-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/NTCED/4.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">智慧命題分析模組</h6>
              </div>
            </a>
          </div>
          <div class="col-sm-6  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/NTCED/1.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">校務任務模組</h6>
              </div>
            </a>
          </div>            
          <div class="col-sm-6  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/NTCED/3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">任務歷程計錄模組</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>



















    <!--台灣拓人題庫系統-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5  mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>台灣拓人題庫系統</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              全省 100 個補習班據點線上考試、出卷、評分、課程、教育系統前後台開發
            </li>
            <li class="pb-2">
              題庫上架 Windows 工具客制化與佈署
            </li>
            <li class="pb-2">
              網路與伺服器私有雲、VM、VPN、防火牆佈建
            </li>
            <li class="pb-2">
              Rest API 開發、支援 Open API 3.0 採用 Postman 工具
            </li>
            <li class="pb-2">
              採用 Docker 架構
            </li>
            <li class="pb-2">
              系統與軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-3">
          <div class="col-sm-6 mt-sm-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web3.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-sm-6 mt-sm-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web3-1.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">學生設定</h6>
              </div>
            </a>
          </div>
          <div class="col-sm-6  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/test1.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">題庫系統</h6>
              </div>
            </a>
          </div>            
          <div class="col-sm-6  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web3-2.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">測驗結果</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 英文線上教學 APP     -->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>英文線上教學 APP </h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              包含，影音、聽力、單字、測驗、課程、離線學習等功能之線上英文教學 APP
            </li>
            <li class="pb-2">
              活動、金流、軟體優化
            </li>
            <li class="pb-2">
              整合影音串流 DRM 系統 、影音串流上架工具
            </li>
            <li class="pb-2">
              影音、聽力導讀、離線收聽模組（支援 Native + Hybrid 模組）
            </li>
            <li class="pb-2">
              Android / IOS 雙系統開發
            </li>
            <li class="pb-2">
              Rest API 開發、支援 Open API 3.0 採用 Postman 工具
            </li>
            <li class="pb-2">
              系統軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-6 col-sm-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/004.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-sm-4 mt-sm-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/004-2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">題庫系統</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-sm-4 mt-sm-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/004-3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">解析內容</h6>
              </div>
            </a>
          </div>            
          <div class="col-6 col-sm-4  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/004-4.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">單字測驗</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-sm-4  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/004-5.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">課程清單</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-sm-4  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/004-6.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">老師講解</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--新北市教育局 -->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5  mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>政府工程 - 新北市教育局 </h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              圖書、社群、書評、互動、推播、智能選書
            </li>
            <li class="pb-2">
              Android / IOS / WEB  三平台開發
            </li>
            <li class="pb-2">
              系統軟體維運服務
            </li>
            <li class="pb-2">
              WEB 通過 OWASP 弱點掃描
            </li>
            <li class="pb-2">
              Android/IOS APP 分別通過 經濟部工業局「行動應用 APP基本資安檢測基準」L2 檢測
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-sm-6 mt-sm-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web-4.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-sm-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web-3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">書籍推薦</h6>
              </div>
            </a>
          </div>
          <div class="col-sm-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web-5.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">獎勵系統</h6>
              </div>
            </a>
          </div>
          <div class="col-sm-6 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web-2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">登入系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--龍騰文化題庫系統-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>龍騰文化題庫系統</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              龍騰好學吧 APP 客制化開發 Android / IOS  雙系統開發
            </li>
            <li class="pb-2">
              Rest API 開發、支援 Open API 3.0 採用 Postman 工具
            </li>
            <li class="pb-2">
              軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-6 col-sm-4 mt-sm-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/lt01.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-sm-4 mt-sm-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/lt02.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">題庫系統</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-sm-4 mt-sm-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/lt03.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">解析內容</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--數位教育產品-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>數位教育產品</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              雲端家教平台
            </li>
            <li class="pb-2">
              題庫系統
            </li>
            <li class="pb-2">
              直播系統
            </li>
            <li class="pb-2">
              Unity遊戲開發
            </li>
            <li class="pb-2">
              系統軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-md-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/teach2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">遊戲選單</h6>
              </div>
            </a>
          </div>
          <div class="col-md-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/teach3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">遊戲畫面</h6>
              </div>
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/sb2.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>

          <div class="col-md-6 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/sb3.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">直播系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> 
  </div>  
  <div
    class="space-180 d-none d-md-block"
    id="live"
  />  
  <div class="container mt-sm-5 mb-5">
    <div
      class="row text-center my-sm-5 mt-5"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-6 mx-auto">
        <h2 class="nicole-text-title text-warning text-gradient mb-0">
          視訊直播
        </h2>
        <h2 class="nicole-text-title">
          Education
        </h2> 
        <p class="nicole-text-name">
          題庫系統、直播教學、報表系統、使用者系統、金流系統、維運服務
        </p>
      </div>
    </div>
    <!--數位電視盒 APP 開發-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5  mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>數位電視盒 APP 開發</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              影音、KTV、網紅 APP 開發
            </li>
            <li class="pb-2">
              Rest API 開發、支援 Open API 3.0 採用 Postman 工具
            </li>
            <li class="pb-2">
              YouTube 影片爬蟲機器人
            </li>
            <li class="">
              系統軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-lg-3">
          <div class="col-md-12 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/tvbox.jpg"
                  alt=""
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>    
  </div>

  <!--KKStream-->
  <section
    class="mt-lg-10 pb-4 bg-gray-900 m-2 m-lg-3 border-radius-xl"
    data-aos="zoom-in"
    data-aos-duration="600"
    data-aos-once="ture"
  >
    <div class="container">
      <div class="row mx-0">
        <div class="col-lg-4 my-auto">
          <h2 class="nicole-text-title text-gradient text-primary mb-0 mt-6">
            KKBox旗下 - KKStream
          </h2>
          <h2 class="nicole-text-title mb-4 text-white">
            For beautiful webapps
          </h2>
          <ul class="nicole-text-name text-white opacity-8">
            <li class="pb-3">
              KKStream SNS Plugin Project 直播管理、Line推播、金流系統前後台與API開發
            </li>
            <li class="pb-3">
              整合 Line 、第三方金流、KKSStream 影音直播的線上直播串流平台
            </li>
            <li class="">
              Rest API 開發、支援 Open API 3.0 採用 Postman 工具
            </li>
          </ul>
        </div>
        <div class="col-lg-8">
          <div class="row mt-lg-n7">
            <div class="col-lg-3 col-6 mt-5">
              <div class="card move-on-hover">
                <a>
                  <img
                    class="w-100 border-radius-xl"
                    src="@/assets/img/010.jpg"
                    alt=""
                  >
                </a>
              </div>

              <div class="card move-on-hover mt-4 p-1">
                <a>
                  <img
                    class="w-100 border-radius-xl"
                    src="@/assets/img/010-4.jpg"
                  >
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="card move-on-hover">
                <a>
                  <img
                    class="w-100 border-radius-xl"
                    src="@/assets/img/010-3.jpg"
                  >
                </a>
              </div>
              <div class="card move-on-hover mt-4">
                <a>
                  <img
                    class="w-100 border-radius-xl"
                    src="@/assets/img/010-2.jpg"
                  >
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-6 mt-5 d-lg-block d-none">
              <div class="card move-on-hover">
                <a>
                  <img
                    class="w-100 border-radius-xl"
                    src="@/assets/img/kks-2.jpg"
                  >
                </a>
              </div>
              <div class="card move-on-hover mt-4">
                <a>
                  <img
                    class="w-100 border-radius-xl"
                    src="@/assets/img/kks-1.jpg"
                    alt=""
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div
    class="space-180  d-none d-md-block"
    id="tri"
  />
    
  <div class="container">
    <div
      class="row text-center my-sm-5 mt-5"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-6 mx-auto">
        <h2 class="nicole-text-title text-primary text-gradient mb-0">
          百貨旅遊
        </h2>
        <h2 class="nicole-text-title ">
          Travel
        </h2> 
        <p class="nicole-text-name">
          題庫系統、直播教學、報表系統、使用者系統、金流系統、維運服務
        </p>
      </div>
    </div>
    <!--物業-->
    <div class="row pt-lg-6 mx-0 ">
      <div class="col-lg-7 order-2 order-md-1">
        <div class="row mt-md-3">
          <div class="col-md-6 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web1.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">訂房系統</h6>
              </div>
            </a>
          </div>            
          <div class="col-md-6 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/talk.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">聊天系統</h6>
              </div>
            </a>
          </div>            
          <div class="col-md-6 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web1-2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">日期系統</h6>
              </div>
            </a>
          </div>            
          <div class="col-md-6 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/web1-3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">金流系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-5 order-1 order-md-2">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-4"
          style="top: 100px"
        >
          <h4>Opus Orbis APP 物業管理 APP 開發</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              仿 Airbnb 功能製作
            </li>
            <li class="pb-2">
              Android / IOS / WEB  三系統開發具
            </li>
            <li class="pb-2">
              GOOGLE MAP 地圖範圍、查尋、篩選模組 （ H5 、Andoroid、IOS 支援 ）
            </li>
            <li class="pb-2">
              系統軟體維運服務
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--富邦-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-7 order-2 order-md-1">
        <div class="row mt-3">
          <div class="col-6 col-md-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/bank1.png"
                  alt=""
                >
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/bank2.png"
                  alt=""
                >
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/bank3.png"
                  alt=""
                >
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-5 order-1 order-md-2">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-4"
          style="top: 100px"
        >
          <h4>金融業社群 APP</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              參展亞洲金融展成功
            </li>
            <li class="pb-2">
              Android / IOS   雙平台開發
            </li>
            <li class="pb-2">
              .NET + MSQL 環境 API 系統開發
            </li>
            <li class="pb-2">
              Android / IOS APP 分別通過 經濟部工業局「行動應用 APP基本資安檢測基準」L3 檢測
            </li>
          </ul>
        </div>
      </div>
    </div> 
    <!--柯達-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-7 order-2 order-md-1">
        <div class="row mt-md-3">
          <div class="col-6 col-md-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/khotel-1.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/khotel-2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">訂房系統</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/khotel-3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">會員系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-5 order-1 order-md-2">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-4"
          style="top: 100px"
        >
          <h4>科達大飯店 </h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              線上訂房 APP
            </li>
            <li class="pb-2">
              Android / IOS   雙平台開發
            </li>
          </ul>
        </div>
      </div>
    </div> 
    <!--百貨-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-7 order-2 order-md-1">
        <div class="row mt-md-3">
          <div class="col-6 col-md-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/q1.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/q2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">得獎頁面</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/q3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">禮券系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-5 order-1 order-md-2">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-4"
          style="top: 100px"
        >
          <h4>百貨業 活動</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              UI/UX APP 設計
            </li>
          </ul>
        </div>
      </div>
    </div> 
  </div>


  <div
    class="space-180 d-none d-md-block"
    id="iot"
  />    
  <div class="container">
    <div
      class="row text-center my-sm-5 mt-5"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-6 mx-auto">
        <h2 class="nicole-text-title text-success text-gradient mb-0">
          IOT 物聯網
        </h2>
        <h2 class="nicole-text-title">
          Internet of Things
        </h2> 
        <p class="nicole-text-name">
          藍芽整合、WI-FI 產品整合、運動與健康管理、醫療保健、安全監控
        </p>
      </div>
    </div>
    <!--牙醫-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>QuicSmile Pro 智能 3D 齒模 APP</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              Android / IOS 雙系統開發
            </li>
            <li class="pb-2">
              Unity3D 技術、智能拍照校正牙齒與臉型
            </li>
            <li class="pb-2">
              系統軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/tooth-4.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/tooth-2.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">齒模系統</h6>
              </div>
            </a>
          </div>
          <div class="col-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/tooth-3.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">校正系統</h6>
              </div>
            </a>
          </div>            
          <div class="col-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/tooth-1.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">齒模系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> 
    <!--智能膚測-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>智能膚測  APP </h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              智能膚測 APP
            </li>
            <li class="pb-2">
              Android / IOS   雙平台開發
            </li>
            <li class="pb-2">
              系統軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-3">
          <div class="col-6 col-md-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/skin01.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/skin03.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">膚質檢測</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/skin02.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">會員系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--氫氧機-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>IOT 輕氧機 APP 雙系統開發</h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              Android / IOS 雙系統開發
            </li>
            <li class="pb-2">
              藍芽系統串接
            </li>
            <li class="pb-2">
              系統軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-6 col-md-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/009-2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/009.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">水質檢測</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/009-3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">設定</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> 
    <!--清真認證-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>清真認證 APP </h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              Android / IOS / WEB 三平台開發
            </li>
            <li class="pb-2">
              農業科技應用展示平台
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-6 col-md-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/rice1.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/rice2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">公告系統</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/rice3.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">動態偵測系統</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--問診系統 -->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>雲端問診系統 </h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              參展日本醫療展
            </li>
            <li class="pb-2">
              整合 WebRTC ， HLS 影音串流，線上問診，預約掛號
            </li>
            <li class="pb-2">
              Android / IOS / WEB   三平台開發
            </li>
            <li class="pb-2">
              雲端診療線上問診應用展示平台
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-3">
          <div class="col-md-6 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/doctor1.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-md-6 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/doctor2.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">視訊問診</h6>
              </div>
            </a>
          </div>
          <div class="col-md-6  mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/doctor3.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">病例紀錄</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--佰龍機械-->
    <div
      class="row pt-lg-6 mx-0"
      data-aos="zoom-in"
      data-aos-duration="600"
      data-aos-once="ture"
    >
      <div class="col-lg-5">
        <div
          class="position-sticky pb-lg-5 pb-3 mt-lg-0 mt-5 ps-2"
          style="top: 100px"
        >
          <h4>佰龍機械 </h4>
          <ul class="text-secondary pe-3 mt-4">
            <li class="pb-2">
              紡織機械 大數據分析 APP
            </li>
            <li class="pb-2">
              Android / IOS 雙系統開發
            </li>
            <li class="pb-2">
              軟體維運服務
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mt-md-3">
          <div class="col-6 col-md-4 mt-md-0 mt-3">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/011.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">首頁</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/011-2.jpg"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">儀器操作</h6>
              </div>
            </a>
          </div>
          <div class="col-6 col-md-4 mt-md-0 mt-4">
            <a>
              <div class="card shadow-lg move-on-hover min-height-1160">
                <img
                  class="w-100 my-auto"
                  src="@/assets/img/011-3.png"
                  alt=""
                >
              </div>
              <div class="mt-2 ms-2">
                <h6 class="mb-0 text-gray">數值回報</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>          
  </div>


  
  <div class="space-180" />




  <!-- ---- START FOOTER 4 dark w/ 2 cols ---- -->
  <Footer />



  <!-- ---- END FOOTER 4 dark w/ 2 cols ---- -->
  <nav id="cd-vertical-nav">
    <ul>
      <li>
        <a
          href="#edu"
          data-number="1"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">教育相關</span>
        </a>
      </li>
      <li>
        <a
          href="#live"
          data-number="2"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">視訊直播</span>
        </a>
      </li>
      <li>
        <a
          href="#tri"
          data-number="3"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">百貨旅遊</span>
        </a>
      </li>
      <li>
        <a
          href="#iot"
          data-number="4"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">Iot物聯網</span>
        </a>
      </li>
    </ul>
  </nav>  
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , Footer } from '@/components/Frame';

export default {
  name: 'App',
  components: {
    SideNav,
    Footer
  },
  setup() {

    const copyrightTime = ref() ;


    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 



    /*
    const smoothScroll = inject('$smoothScroll') ;
    onMounted( async () => {
      smoothScroll({
        scrollTo: document.getElementById('header') ,
        hash: '#header' // required if updateHistory is true
      });
    }) ;
    */

    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      copyrightTime
    };
  },
  created() {



  },
  mounted() {
    //window.location.hash = '#header';
    //console.log(this.$smoothScroll);
    this.$smoothScroll({
      scrollTo: document.getElementById('header'),
      //hash: '#header' // required if updateHistory is true
    });

  },
  methods: {
  }
};
</script>
