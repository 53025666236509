/* eslint-disable import/no-unresolved */
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'; 
import tw from '@/i18n/zh-TW.json';
import cn from '@/i18n/zh-CN.json';
import en from '@/i18n/en-US.json';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';



import AOS from 'aos';
import 'aos/dist/aos.css';

import VueSmoothScroll from 'v-smooth-scroll';


import swal from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


import '@/assets/css/googleFontBestOpenSans.css'; // open-sans 
import '@/assets/css/nucleo-icons.css';
import '@/assets/css/nucleo-svg.css';
import '@/assets/css/soft-design-system-pro.css';
import '@/assets/css/vertical-nav.css';
import '@/assets/css/test.css';

// eslint-disable-next-line import/no-unresolved
//import '@/styles/element/index.scss';
// 開發時全部都安裝等要封裝時再來減肥
//import ElementPlus from 'element-plus';
// 只安裝要使用的 元件
//import {ElPagination , ElTabs , ElTransfer , ElSwitch , ElRate} from 'element-plus';a

//import '@/styles/fontawesome6/scss/fontawesome.scss';
//import '@/styles/fontawesome6/scss/solid.scss';
//import '@/styles/fontawesome6/scss/brands.scss';
import '@/styles/fontawesome6/scss/app.scss';
//import '@/styles/oltAdmin/material-dashboard/bootstrap/bootstrap.scss';
//import '@/styles/oltAdmin/material-dashboard.scss';


const swalOptions = {
  confirmButtonColor: '#e91e63',
  cancelButtonColor: '#333333',
};

const i18n = createI18n({
  legacy: false,
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  messages: {
    'zh-TW': tw,
    'zh-CN': cn,
    'en-US': en,
  }
});

createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(swal,swalOptions)
  .use(AOS.init())
  .use(VueSmoothScroll, {
    duration: 500,
    updateHistory: false,
    //updateHistory: true,
  })
  .mount('#app');
