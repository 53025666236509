
<template>
  <SideNav />
  <div class="overflow-hidden-phone h-120-vh">
    <div class="page-header wrapperpic ">
      <div
        class="position-absolute fixed-top ms-auto col-md-8 col-xl-6 h-100 rounded-4 z-index-0  me-md-n4 d-none d-md-block "
        style="background-image: url('assets/img/meet1.jpg'); background-size: cover;"
      />    
      <div
        class="col-12 position-absolute fixed-top h-100 rounded-4 z-index-0  me-md-n4  d-md-none"
        style="background-image: url('assets/img/meet1.jpg'); background-size: cover;"
      />


      <div class="container mt-7  mt-lg-0 mb-6">
        <div class="col-md-8 col-lg-7 d-flex justify-content-center flex-column">
          <div class="card p-3 p-lg-3 d-flex justify-content-center shadow-lg  blur align-items-center">
            <h4
              class="text-bold text-center lh-base text-gradient text-primary"
              data-aos="zoom-in nicole-text-title"
              data-aos-duration="1000"
            >
              實現您的創意 
            </h4>
            <h5
              class="text-center lh-base text-gradient text-primary nicole-text-time"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Let’s Talk About Your Project
            </h5>
            <p
              class="text-left text-lg-center nicole-text-time"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              請告訴我們你的需求，將會有專人儘速和您聯繫。
            </p>
            <form
              class="w-100"
              data-aos="zoom-in"
              data-aos-duration="1000"
              @submit="onSubmit"
            >
              <div class="p-2 py-xl-4  px-xl-5 ">
                <div class="d-lg-flex ">
                  <div class="col-lg-6 pe-lg-3  mb-4">
                    <label class="text-16">
                      姓名 
                    </label>
                    <label
                      class="text-10 text-danger"
                      v-if="nameError"
                    >

                      * {{ nameError }}
                    </label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="請輸入您的姓名"
                        aria-label="First Name..."
                        type="text"
                        v-model="name"
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 ps-lg-2  mb-4">
                    <label class="text-16">公司名稱</label>
                    <label
                      class="text-10 text-danger"
                      v-if="compannyError"
                    >

                      * {{ compannyError }}
                    </label>
                    <div
                      class="input-group"
                      id="company"
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="請輸入公司名稱"
                        v-model="companny"
                      >
                    </div>
                  </div>
                </div>
                <div class="d-lg-flex">
                  <div class="col-lg-6 pe-lg-3  mb-4">
                    <label class="text-16">聯絡電話
                      <label
                        class="text-10 text-danger"
                        v-if="mobileError"
                      >

                        * {{ mobileError }}
                      </label>                 
                    </label>
                    <div class="input-group">
                      <input
                        type="tel"
                        class="form-control"
                        placeholder="請輸入聯絡電話"
                        v-model="mobile"
                      >
                    </div>
                  </div>                
 

                  <div class="col-lg-6 ps-lg-2 mb-4">
                    <label class="text-16">Email Address</label>
                    <label
                      class="text-10 text-danger"
                      v-if="emailError"
                    >

                      * {{ emailError }}
                    </label>                  
                    <div class="input-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="請輸入e-mail"
                        v-model="email"
                      >
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <label class="text-16">預算範圍</label>
                  <label
                    class="text-10 text-danger"
                    v-if="budgetError"
                  >

                    * {{ budgetError }}
                  </label>                    
                  <select
                    class="form-control"
                    placeholder="Departure"
                    v-model="budget"
                  >
                    <option
                      value=""
                      selected=""
                    >
                      請選擇預算範圍
                    </option>
                    <option value="20-50萬">
                      20-50萬
                    </option>
                    <option value="50-100萬">
                      50-100萬
                    </option>
                    <option value="100-200萬">
                      100-200萬
                    </option>
                    <option value="200-300萬">
                      200-300萬
                    </option>
                    <option value="300-400萬">
                      a
                      300-400萬
                    </option>
                    <option value="400萬以上">
                      400萬以上
                    </option>
                  </select>
                </div>              
                <div class="form-group mb-5">
                  <label class="text-16">請簡述想要您的需要的服務內容、預期完成時間…等 (字數上限：2,000字)</label>
                  <textarea
                    class="form-control"
                    placeholder="有任何idea，讓海煜科技協助您規劃：）"
                    rows="4"
                    v-model="memo"
                  />
                  <label
                    class="text-10 text-danger"
                    v-if="memoError"
                  >

                    * {{ memoError }}
                  </label>                    
                </div>
                <div class="col-md-12 mb-4 mb-lg-0">
                  <button
                    type="submit"
                    class="btn bg-gradient-dark w-100 text-16"
                  >
                    送出
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, toRefs , reactive , watch , inject , onMounted , onBeforeUpdate , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
import moment from 'moment' ;
import { useI18n } from 'vue-i18n';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { SideNav } from '@/components/Frame';
export default {
  name: 'App',
  components: {
    SideNav,
  },
  setup(props,{emit}) {
    /**
     *  swal start
     */
    const swal = inject('$swal') ;
    /**
     *  swal end 
     */

    /**
     *  i18n Start 
     */
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';
    /**
     *  i18n end 
     */

    const apiName = ref('contacts');
    //const pkyKey = ref('id');`

    //const seq = ref('10');

    const schema = yup.object({
      name: yup.string()
        .min(2,t('messages.min',{length:2}))
        .max(32,t('messages.max',{length:32}))    
        .required(t('messages.required')),           
      companny: yup.string()
        .min(2,t('messages.min',{length:2}))
        .max(32,t('messages.max',{length:32}))    
        .required(t('messages.required')),         
      email: yup
        .string()
        .nullable(true)
        .required(t('messages.required'))
        .email(t('messages.email')),                  
      mobile: yup.string()
        .min(2,t('messages.min',{length:2}))
        .max(32,t('messages.max',{length:32}))    
        .required(t('messages.required')),        
      budget: yup.string()
        .min(2,t('messages.min',{length:2}))
        .max(32,t('messages.max',{length:32}))    
        .required(t('messages.required')),        
      memo: yup.string()
        .min(10,t('messages.min',{length:10}))
        .max(2000,t('messages.max',{length:2000}))
        .required(t('messages.required')),        
 
    });
    // Create a form context with the validation schema

    const { handleSubmit , resetForm } = useForm({
      validationSchema: schema,
    });
    // No need to define rules for fields
    const { value: name, errorMessage: nameError , meta: nameMeta } = useField('name');
    const { value: companny, errorMessage: compannyError , meta: compannyMeta } = useField('companny');
    const { value: mobile, errorMessage: mobileError , meta: mobileMeta } = useField('mobile');
    const { value: email, errorMessage: emailError , meta: emailMeta } = useField('email');
    const { value: budget, errorMessage: budgetError , meta: budgetMeta } = useField('budget');
    const { value: memo, errorMessage: memoError , meta: memoMeta } = useField('memo');





    // 初始化 表單的值
    function initForm(){
      //seq.value = 10 ; 
      //$switch.value = true ; 
    }
    initForm();
    //console.log('nameError : ' , nameError.value);
    //console.log('nameMeta : ' , nameMeta.valid);

    function onInvalidSubmit({ values, errors, results }) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
      swal.fire({
        icon:'error',
        html:'請確實填寫資料！',
      //showConfirmButton: false,
      //buttonsStyling: true,
      //confirmButtonClass: 'md-button md-success',
      //confirmButtonText:this.t('messages.confirm_button'),
      });   
    }


    // use `onSubmit` as an event handler for your forms
    const onSubmit = handleSubmit(values => {
      // pretty print the values object
      console.log('onSubmit : ',values);
      postOneRecord(values);
    },onInvalidSubmit);

    function postOneRecord(form){
      
      const apiParam = {
        // auth
        token:'' ,// token key from veex store 
        // Debug Mode 
        apiPostDebug:false , // API 新增 除錯模式
        apiName:apiName.value,
        form:form ,
      };

      //console.log(param); 
      let resObj = oAA.postRecordVue3(apiParam) ; 


      resObj
        .then((res)=> {
          console.log(res);
          swal.fire({
            icon:'success',
            html:t('messages.insert_success', {ex:'<br/>感謝您的耐心填寫，我們將會有專人與您聯絡！'}),
            buttonsStyling: true,
            confirmButtonClass: 'md-button md-success',
            confirmButtonText:t('messages.confirm_button'),
          }).then( result => {
            if (result.value) {
              console.log('result : ' , result);
              resetForm();
              initForm();
              // eslint-disable-next-line no-undef
              //hideModal('add');
              //emit('addCallBack');
            }
          });
        })
        .catch((err)=> {
          oApiError.chkError(err,swal); 
          // 每一個欄位錯誤 都會帶回 account[0] , 你必需在這裡填寫 欄位回傳錯誤的訊息 下一版我將會封裝它的
          if(err.response.status == '422'){
            //console.log('ERROR : ');
            //console.log(err.response.data.error);
            if(err.response.data){
              //this.errMsg = err.response.data.error ; 
              swal.fire({
                icon:'error',
                html:'新增失敗，請填寫正確的資料！',
                buttonsStyling: true,
                confirmButtonClass: 'md-button md-success',
                confirmButtonText:t('messages.confirm_button'),
              });              
            }
          }
        });



    }


    /**
     *  修改新增資料 在 刷新時重新初始化 material dashboard 的 js 設定 
     *  initAdmion.js Start
     */
    onUpdated( async () => {
    //onBeforeUpdate( async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals 
      // eslint-disable-next-line no-undef
      //await closeAllModals() ;
      // eslint-disable-next-line no-undef
      //await showModal('add') ; 
      // eslint-disable-next-line no-undef
      //await initMdInput() ;
    }) ;
    onMounted( async () => {
      // eslint-disable-next-line no-undef
      //await initMdInput() ;
      this.$smoothScroll({
        scrollTo: document.getElementById('header'),
      //hash: '#header' // required if updateHistory is true
      });
    });
    /**
     *  initAdmion.js End
     */

    return {
      // moment 
      moment,
      // i18n
      t,
      locale,      
      // add form
      name,
      nameError,
      nameMeta,
      companny,
      compannyError,
      compannyMeta,
      mobile,
      mobileError,
      mobileMeta,
      budget,
      budgetError,
      budgetMeta,
      email,
      emailError,
      emailMeta,
      memo,
      memoError,
      memoMeta,
      onSubmit,
    };
  },
};
</script>