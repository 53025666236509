
<template>
  <SideNav />

  <section>
    <div class="container-fluid overflow-hidden h-100-vh">
      <div class="row">
        <div class="col-lg-6 order-2 order-md-2 order-lg-1 z-index-1 ">
          <div
            class="container ml-n7 mt-3 mt-md-n3 mt-lg-n8"
            style="transform: rotate(20deg);"
          >
            <div class="row">
              <div class="col-lg-3 col-md-3 col-3">
                <img
                  class="w-100 mt-5 mt-md-10 mb-4 fadeIn2 fadeInBottom"
                  src="@/assets/img/iphone-2.png"
                >
                <img
                  class="w-100 mb-4"
                  src="@/assets/img/iphone-3.png"
                >
                <img
                  class="w-100"
                  src="@/assets/img/iphone-1.png"
                >
              </div>
              <div class="col-lg-3 col-md-3 col-3">
                <img
                  class="w-100 mb-4 mt-3 mt-md-8"
                  src="@/assets/img/iphone-1.png"
                >
                <img
                  class="w-100 mb-4"
                  src="@/assets/img/iphone-4.png"
                >
                <img
                  class="w-100 fadeIn3 fadeInBottom"
                  src="@/assets/img/iphone-2.png"
                >
              </div>
              <div class="col-lg-3 col-md-3 col-3">
                <img
                  class="w-100 mb-4 mt-0 mt-md-n12 mt-lg-7 fadeIn3 fadeInBottom"
                  src="@/assets/img/iphone-1.png"
                >
                <img
                  class="w-100 mb-4"
                  src="@/assets/img/iphone-2.png"
                >
                <img
                  class="w-100"
                  src="@/assets/img/iphone-3.png"
                >
              </div>
              <div class="col-lg-3 col-md-3 col-3">
                <img
                  class="w-100 mb-4 mt-n10"
                  src="@/assets/img/iphone-1.png"
                >
                <img
                  class="w-100 mb-4 fadeIn1 fadeInBottom"
                  src="@/assets/img/iphone-1.png"
                >
                <img
                  class="w-100"
                  src="@/assets/img/iphone-4.png"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="height-100 d-lg-none d-block" />
        <div
          class="col-lg-4 col-md-8 my-auto ml-lg-7 order-1 order-md-1 order-lg-1 pl-3 pl-md-5 pl-lg-0 z-index-2 ms-lg-5 ptproduct"
          data-aos="zoom-in"
          data-aos-duration="600"
          data-aos-once="ture"        
        >
          <h1 class="text-gradient text-primary ">
            CRStream
          </h1>
          <div class="row">
            <div class="col-md-12">
              <div
                class="accordion"
                id="meta"
              >
                <div class="accordion-item mb-3">
                  <h5
                    class="accordion-header"
                    id="headingThree"
                  >
                    <button
                      class="accordion-button border-bottom font-weight-bold text-left collapse show"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      產品說明
                      <i
                        class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                      <i
                        class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingThree"
                    data-bs-parent="#meta"
                    style=""
                  >
                    <div class="accordion-body opacity-8">
                      <ul class="nicole-text-name">
                        <li>影音串流系統</li>
                        <li>DRM 影音內容加密與安全</li>
                        <li>Windows 加密上架工具，支援浮水印，各種解析度</li>
                        <li>API 串接平台與工具</li>
                        <li>提供SDK 支援 HTML5，Android、IOS、Unity3D VR 平台整合</li>
                        <li>提供支援 影音文章互動與離線 HTML5，Android、IOS</li>
                      </ul> 
                    </div>
                  </div>
                </div>

                <div class="accordion-item mb-3">
                  <h5
                    class="accordion-header"
                    id="headingFour"
                  >
                    <button
                      class="accordion-button border-bottom font-weight-bold text-left collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      應用情境
                      <i
                        class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                      <i
                        class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#meta"
                    style=""
                  >
                    <div class="accordion-body opacity-8">
                      <p class="nicole-text-name">
                        文章導讀教學、聽力測驗、補課系統、涵授課程、演講或活動
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h5
                    class="accordion-header"
                    id="headingOne"
                  >
                    <button
                      class="accordion-button border-bottom font-weight-bold text-left collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      系統架構支援
                      <i
                        class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                      <i
                        class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#meta"
                    style=""
                  >
                    <div class="accordion-body opacity-8">
                      <ul class="nicole-text-name">
                        <li>支援 Docker 架構，輕便快速在幾分鐘內在任何作業系統下佈署完成本系統。</li>
                        <li>可佈建於公有雲（AWS、GCP、Azure、OCI、Linode），私有雲（微型伺服器、VM Server ）</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h5
                    class="accordion-header"
                    id="headingTwo"
                  >
                    <button
                      class="accordion-button border-bottom font-weight-bold text-left collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      支援Client端應用環境
                      <i
                        class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                      <i
                        class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                        aria-hidden="true"
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#meta"
                    style=""
                  >
                    <div class="accordion-body opacity-8">
                      <ul class="nicole-text-name">
                        <li>Android /IOS/WEB </li>
                        <li>Unity3D VR</li>
                      </ul> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    
  <!-- -------- END Features w/ icons and text on left & gradient title and text on right -------- -->
  <div
    class="section section-products"
    data-aos="zoom-in"
    data-aos-duration="600"
    data-aos-once="ture"      
  > 
    <div class="ms-md-5 ms-lg-10 ">
      <div class="row mx-0"> 
        <div class="col-lg-5 mt-8"> 
          <h1 class="text-gradient text-info">
            TronEdu Exam
          </h1>
          <div
            class="accordion"
            id="exam"
          >
            <div class="accordion-item mb-3">
              <h5
                class="accordion-header"
                id="headingEight"
              >
                <button
                  class="accordion-button border-bottom font-weight-bold text-left collapse show"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  產品說明
                  <i
                    class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                  <i
                    class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                </button>
              </h5>
              <div
                id="collapseEight"
                class="accordion-collapse collapse show"
                aria-labelledby="headingEight"
                data-bs-parent="#exam"
                style=""
              >
                <div class="accordion-body opacity-8">
                  <p class="nicole-text-name">
                    雲端考試出題評分系統
                  </p>
                  <ul class="nicole-text-name">
                    <li>連鎖補習班或教育機構線上考試、出題、評分、解答系統</li>
                    <li>雲端學習平台線上考試、出題、評分、解答系統</li>
                  </ul>                 
                  <p class="nicole-text-name">
                    Microsoft Office. Word 圖庫編輯與上架工具
                  </p>
                  <ul class="nicole-text-name">
                    <li>降低編輯學習門橍 </li>
                    <li>轉貼內容即可批次上架</li>
                    <li>解決數學化學等科目特殊符號問題 </li>
                  </ul> 
                  <p class="nicole-text-name">
                    題庫資源管理後台
                  </p>
                  <ul class="nicole-text-name">
                    <li>支援題庫管理內容正確性二校流程</li>
                    <li>預覽題庫與考題</li>
                    <li>
                      Word 工具上傳資源，輸出格式支援  SVG、PNG、WORD 格式
                    </li>
                  </ul> 
                </div>
              </div>
            </div>

            <div class="accordion-item mb-3">
              <h5
                class="accordion-header"
                id="headingFive"
              >
                <button
                  class="accordion-button border-bottom font-weight-bold text-left collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  應用情境
                  <i
                    class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                  <i
                    class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                </button>
              </h5>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#exam"
                style=""
              >
                <div class="accordion-body opacity-8">
                  <p class="nicole-text-name">
                    考試系統、題庫系統、後台題庫編輯
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item mb-3">
              <h5
                class="accordion-header"
                id="headingSix"
              >
                <button
                  class="accordion-button border-bottom font-weight-bold text-left collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  系統架構支援
                  <i
                    class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                  <i
                    class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                </button>
              </h5>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#exam"
                style=""
              >
                <div class="accordion-body opacity-8">
                  <ul class="nicole-text-name">
                    <li>支援 Docker 架構，輕便快速在幾分鐘內在任何作業系統下佈署完成本系統。</li>
                    <li>可佈建於公有雲（AWS、GCP、Azure、OCI、Linode），私有雲（微型伺服器、VM Server ）</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item mb-3">
              <h5
                class="accordion-header"
                id="headingSeven"
              >
                <button
                  class="accordion-button border-bottom font-weight-bold text-left collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  支援Client端應用環境
                  <i
                    class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                  <i
                    class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                    aria-hidden="true"
                  />
                </button>
              </h5>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#exam"
                style=""
              >
                <div class="accordion-body opacity-8">
                  <ul class="nicole-text-name">
                    <li>Android /IOS/WEB </li>
                  </ul> 
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div class="col-12 col-lg-7 position-relative hidelab"> 
          <div class="laptop"> 
            <div class="shadow" /> 
            <div class="lid" /> 
            <div class="screen"> 
              <div
                id="mac"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#mac"
                    data-bs-slide-to="0"
                    class="active"
                  />
                  <li
                    data-target="#mac"
                    data-bs-slide-to="1"
                  />
                  <li
                    data-target="#mac"
                    data-bs-slide-to="2"
                  />
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="@/assets/img/test1.png"
                      alt="First slide"
                    >
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="@/assets/img/cloud.jpg"
                      alt="Second slide"
                    >
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="@/assets/img/sb1.png"
                      t="Third slide"
                    >
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#mac"
                  role="button"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#mac"
                  role="button"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div> 
            <div class="bottom"> 
              <div class="keyboard" /> 
              <div class="trackpad" /> 
            </div> 
          </div> 
        </div>
      </div> 
    </div> 
  </div>


  <!-- -------- START PRE-FOOTER 8 w/ TEXT, BG IMAGE AND 2 BUTTONS ------- -->

  <div class="height-200 d-none d-md-block" />   
  <div class="height-200" />   
  <Footer />
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , Footer } from '@/components/Frame';

export default {
  name: 'App',
  components: {
    SideNav,
    Footer
  },
  setup() {

    const copyrightTime = ref() ;

    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 

    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      copyrightTime
    };
  },
  created() {
  },
  mounted() {
    this.$smoothScroll({
      scrollTo: document.getElementById('header'),
      //hash: '#header' // required if updateHistory is true
    });
  },
  methods: {
  }
};
</script>
