import { dev } from '@/include/config';
class oltConsoleModule{
  log(str,obj){
    if(this.debugMode){
      if(obj){
        console.log(str,obj);
      }
      else{
        console.log(str);
      }
    }
  }

  setDebugMode(bol){
    this.debugMode = bol;
  }


  sysExpireTime(){
    let d = new Date(Date.parse(this.ttime .replace(/-/g,'/')));
    let curDate = new Date();

    if(d <= curDate){
      console.log(this.expMessage);
      this.oltExpire = true ; 
      this.sysExpireAlert();
    }else if(this.oltVtype == 'dev'){
      console.log(this.devMessage);
      console.log('此系統為海煜科技開發版本而非正式授權版，使用授權將於：' + this.ttime + ' 過期\n');
    }
    else{
      console.log('此系統為海煜科技正式授權版，使用授權將於：' + this.ttime + ' 過期\n');
    }
  }

  sysExpireAlert(){
    alert(this.expMessage);
    //window.location.href = 'http://oceanlighttech.com';
  }


  constructor () {
    this.debugMode = dev.debugMode ; 
    this.log('Init oltConsole!!!');
    this.ttime = '2025-12-30 23:59:59'; 
    this.devMessage = '您目前使用的是版權為海煜科技開發人員版本，本系統將於'+this.ttime+' 過期！若您想要獲取正式授權版本請聯絡 <a href=\'http://oceanlighttech.com\' target=\'_blank\'> 海煜科技股份有限公司 </a>' ; 
    this.expMessage = '您的系統己於：' + this.ttime + '過期，若您想要獲取正式授權版本請聯絡 <a href=\'http://oceanlighttech.com\' target=\'_blank\'> 海煜科技股份有限公司 </a>\n';

    this.oltVtype = 'prd'; // prd , dev , 
    if(this.oltVtype == 'dev'){
      this.isDev = true ; 
    }
  }




}

// eslint-disable-next-line new-cap
const oltConsole = new oltConsoleModule();

export { oltConsole };
