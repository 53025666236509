// 框架封裝取用並初始化 EXPORT 給 VUE 
import { config } from '@/include/config';
// 這個封裝要使用時才拿 
//import { dataTable } from "@/lib/dataTable";
import { auth } from '@/lib/oltModule/oltAuth';
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { oltRestApiClient } from '@/lib/oltModule/oltRestApiClientVue3';
import { oltApiAccess } from '@/lib/oltModule/oltApiAccessVue3';
import { oltApiError } from '@/lib/oltModule/oltApiError';
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap';


//console.log(oltObj);
//oltObj.dataTable.set('aaa','bbb');
//
//let oAuth = new auth ; 
//let oltUserToken = oAuth.isLogin();

// eslint-disable-next-line new-cap
let oAA = new oltApiAccess() ; 

let oApiError = new oltApiError() ; 


//oltConsole.log("oltUserToken : ");
//oltConsole.log(oltUserToken);
//
export { config , auth , oltConsole , oltRestApiClient , oAA , oApiError };
