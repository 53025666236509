
<template>
  <SideNav />
  <section
    class="py-7 position-relative "
    style="background-image: url(&#39;assets/img/curved-images/curved22.jpg&#39;); background-size:cover;"
  >
    <span class="mask bg-gradient-info" />
    <div
      class="container position-relative mb-4"
    >
      <div
        class="row"
     >
        <div
          class="col-md-8 mx-auto text-center mb-4 mt-7"
          data-aos="zoom-in"
          data-aos-duration="600"
          data-aos-once="ture"
        >
          <!--
        <div class="icon icon-shape bg-gradient-info shadow text-center mb-3">
          <i class="fas fa-users"></i>
        </div>
-->
          <h3 class="nicole-text-title text-white text-normal letter-spacing-2">
            我們能做什麼
          </h3>
          <h3 class="nicole-text-title2 text-normal opacity-7">
            what we can do
          </h3>
        </div>
      </div>

      <div
        class="row pt-5"
        id="d1"
      >
        <div
          class="col-12"
          data-aos="zoom-in"
          data-aos-duration="600"
          data-aos-once="ture"
        >
          <div class="card">
            <div class="row m-0">
              <div class="mx-auto col-lg-6 col-10 py-5 pe-lg-0 my-auto">
                <img
                  class="w-100 border-radius-md px-md-5  px-xl-7"
                  src="@/assets/img/uiux.jpg"
                >
              </div>
              <div class="col-lg-6 col-12 ps-lg-0 my-auto">
                <div class="pt-3 py-md-5 px-3 text-left">
                  <h4 class="text-gradient text-info mb-3">
                    UI/UX規畫
                  </h4>
                  <p class="text-18 mb-3">
                    你的產品、你的服務值得最合適的方式來呈現，讓你的客戶不只需要，甚至想要使用。我們有豐富的規劃設計經驗，與傑出的開發團隊，可以精準的幫你把想傳遞的價值交給用戶，為你創造最大價值！
                  </p>
                  <ul class="nicole-text-name text-gray">
                    <li class="pb-2">
                      溝通最佳設計方案
                    </li>
                    <li class="pb-2">
                      規劃使用者情境
                    </li>
                    <li class="pb-2">
                      創造友善操作介面
                    </li>
                    <li class="pb-2">
                      Wireframe / Mockup  製作
                    </li>
                  </ul>
                </div>
              </div>
              <hr class="horizontal dark mb-0">
              <div class="col-12  col-xl-9 mx-auto mt-4 mb-3">
                <div class="row d-md-flex justify-content-center m-0">
                  <div class="col-logoicon2  p-0 text-center ">
                    <img
                      src="@/assets/img/figma.svg"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-2">
                      Figma
                    </p>
                  </div>
                  <div class="col-logoicon2  p-0 text-center">
                    <img
                      src="@/assets/img/axure.jpeg"
                      style="object-fit: contain; width: 82px; height: 40px;"
                    >
                    <p class="small my-2">
                      Axure
                    </p>
                  </div>
                  <div class="col-logoicon2  p-0 text-center">
                    <img
                      src="@/assets/img/html.webp"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-2">
                      HTML5
                    </p>
                  </div>
                  <div class="col-logoicon2  p-0 text-center mt-md-4 mt-lg-0">
                    <img
                      src="@/assets/img/bootstrap.webp"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-2">
                      Bootstrap
                    </p>
                  </div>                
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-4 mt-lg-0">
                    <img
                      src="@/assets/img/Sketch_Logo.svg"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-2">
                      Sketch
                    </p>
                  </div>                  
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-4 mt-lg-0">
                    <img
                      src="@/assets/img/adobe.png"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-2">
                      Adobe
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>      
        <div
          class="col-12"
          data-aos="zoom-in"
          data-aos-duration="600"
          data-aos-delay="300"
          id="d2"
          data-aos-once="ture"
        >
          <div class="card mt-6">
            <div class="row m-0">
              <div class="col-lg-7 col-12 my-auto order-2 order-md-2 order-lg-1">
                <div class="pt-3 py-md-5 px-3 text-left">
                  <h4 class="text-gradient text-warning mb-3">
                    APP 開發
                  </h4>
                  <ul class="nicole-text-name text-gray">
                    <li class="pb-2">
                      IOS/Android 雙系統開發
                    </li>
                    <li class="pb-2">
                      Iphone / Ipad / Android Phone / Android. Pad. / Smart TV
                    </li>
                    <li class="pb-2">
                      手機推播
                    </li>
                    <li class="pb-2">
                      Firebase
                    </li>                  
                    <li class="pb-2">
                      AR/VR
                    </li>
                    <li class="pb-2">
                      Oauth 2.0 （Google / FB / Line / Apple ID ）
                    </li>
                    <li class="pb-2">
                      多元選擇 Native，Hybrid、Cross App 技術。（目前己不提供 Web View 技術
                    </li>     
                    <li class="pb-2">
                      經濟部工業局「行動應用App基本資安檢測基準」L1/L2/L3檢驗。
                    </li>
                    <li class="pb-2">
                      協助支援 IOS/Andorid  Store 上架與平台維運。
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mx-auto col-lg-5 col-10 py-5 my-auto order-1 order-md-1 order-lg-2">
                <img
                  class="w-100 border-radius-md px-md-3"
                  src="@/assets/img/app.jpg"
                >
              </div>
              <hr class="horizontal dark mb-0 order-3 order-md-3 order-lg-3">
              <div class="col-12  col-xl-9 mx-auto mt-4 mb-3 order-3 order-md-3 order-lg-3">
                <div class="row d-md-flex justify-content-md-start justify-content-lg-between  m-0">
                  <div class="col-logoicon p-0 text-center">
                    <img
                      src="@/assets/img/swift.webp"
                      class="avatar avatar-40 rounded-0"
                    >  
                    <p class="small my-2">
                      Swift
                    </p>
                  </div>
                  <div class="col-logoicon  p-0 text-center">
                    <img
                      src="@/assets/img/objc.webp"
                      class="avatar avatar-40 rounded-0"
                    >  
                    <p class="small my-2">
                      Object C
                    </p>
                  </div>

                  <div class="col-logoicon  p-0 text-center">
                    <img
                      src="@/assets/img/java.webp"
                      class="avatar avatar-40 rounded-0"
                    >  
                    <p class="small my-2">
                      Java
                    </p>
                  </div>                
                  <div class="col-logoicon  p-0 text-center">
                    <img
                      src="@/assets/img/flutter.webp"
                      class="avatar avatar-40 rounded-0"
                    >  <p class="small my-2">
                      Fluter
                    </p>
                  </div>                  
                  <div class="col-logoicon  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/unity.svg"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-2">
                      Unity 3D
                    </p>
                  </div>                  
                  <div class="col-logoicon  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/vue.webp"
                      class="avatar avatar-40 rounded-0"
                    >  <p class="small mt-1">
                      Vue
                    </p>
                  </div>                  
                  <div class="col-logoicon  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/Xamarin.png"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-2">
                      Xamarin
                    </p>
                  </div>
                  <div class="col-logoicon  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/react.webp"
                      class="avatar avatar-40 rounded-0 "
                    >  
                    <p class="small my-2 text-nowrap">
                      React Native
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12"
          data-aos="zoom-in"
          data-aos-duration="600"
          data-aos-delay="300"
          data-aos-once="ture"
          id="d3"
        >
          <div class="card mt-6 mb-8">
            <div class="row m-0">
              <div class="mx-auto col-lg-5 col-10 py-5 my-auto">
                <img
                  class="w-100 border-radius-md p-md-5"
                  src="@/assets/img/web.jpg"
                >
              </div>
              <div class="col-lg-6 col-12 my-auto ">
                <div class="pt-3 py-md-5 px-3 text-left">
                  <h4 class="text-gradient text-primary mb-3">
                    API 開發
                  </h4>
                  <ul class="nicole-text-name text-gray">
                    <li class="pb-2">
                      支援系統：Windows、Linux、Docker 環佈署
                    </li>
                    <li class="pb-2">
                      採用 Restful API 架構風格，支援 OpenAPI 3.0 格式 API 文件
                    </li>
                    <li class="pb-2">
                      Owasp 弱掃驗證
                    </li>
                    <li class="pb-2">
                      第三方 系統 與 API串接
                    </li>                  
                    <li class="pb-2">
                      管理後台
                    </li>
                  </ul>
                </div>
              </div>
              <hr class="horizontal dark mb-0">
              <div class="col-12 col-xl-9  mx-auto mt-4 mb-3">
                <div class="row d-md-flex justify-content-md-start m-0">
                  <div class="col-logoicon2 p-0 text-center">
                    <img
                      src="@/assets/img/PHP.png"
                      style="object-fit: contain; width: 68px; height: 40px;"
                    >  
                    <p class="small mt-1">
                      PHP
                    </p>
                  </div>
                  <div class="col-logoicon2  p-0 text-center">
                    <img
                      src="@/assets/img/NET_Core.png"
                      class="avatar avatar-40 rounded-0"
                    >  
                    <p class="small my-1 text-nowrap">
                      .net Core
                    </p>
                  </div>
                  <div class="col-logoicon2  p-0 text-center">
                    <img
                      src="@/assets/img/Python.webp"
                      class="avatar avatar-40 rounded-0"
                    >  
                    <p class="small my-1">
                      Python
                    </p>
                  </div>
                  <div class="col-logoicon2  p-0 text-center">
                    <img
                      src="@/assets/img/Node.js.png"
                      style="object-fit: contain; width: 82px; height: 40px;"
                    >  
                    <p class="small my-1">
                      Node JS
                    </p>
                  </div>                
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/msql.png"
                      class="avatar avatar-40 rounded-0"
                    >  <p class="small my-1">
                      Msql
                    </p>
                  </div>                     
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/Redis_Logo.svg"
                      class="avatar avatar-40 rounded-0"
                    >  <p class="small my-1">
                      Msql
                    </p>
                  </div>                  
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/MySQL.webp"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-1 text-nowrap">
                      Mysql
                    </p>
                  </div>                  
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/SQL_Lite.png"
                      class="avatar avatar-40 rounded-0"
                    >  <p class="small mt-1">
                      SQL Lite
                    </p>
                  </div>                  
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/Mongodb.webp"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-1">
                      MongoDB
                    </p>
                  </div>                  
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/Redis_Logo.svg"
                      class="avatar avatar-40 rounded-0"
                    >  <p class="small mt-1">
                      Redis
                    </p>
                  </div>                  
                  <div class="col-logoicon2  p-0 text-center mt-4 mt-md-0">
                    <img
                      src="@/assets/img/Memcache.png"
                      class="avatar avatar-40 rounded-0"
                    >
                    <p class="small my-1">
                      Memcache
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-absolute w-100 z-index-1 bottom-0">
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 40"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="moving-waves">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="-1"
            fill="rgba(255,255,255,0.40"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(255,255,255,0.35)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(255,255,255,0.25)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="8"
            fill="rgba(255,255,255,0.20)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="13"
            fill="rgba(255,255,255,0.15)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="16"
            fill="rgba(255,255,255,0.95"
          />
        </g>
      </svg>
    </div>
  </section> 
  <!-- -------- START CONTENT 11 w/ title and 4 images ------- -->
  <section
    class="py-5 py-md-10"
    id="d4"
  >
    <div
      class="container"
      data-aos="fade-down"
      data-aos-duration="700"
      data-aos-delay="300"
      data-aos-once="ture"
    >
      <div class="row">
        <div class="col-lg-3 col-md-12 position-relative mb-sm-0 mb-3">
          <hr class="vertical dark d-md-block d-none">
          <h3 class="mb-3 text-gradient text-info">
            網站設計
          </h3>
          <ul class="nicole-text-name  text-left">
            <li class="pb-2">
              RWD 響應式網頁
            </li>
            <li class="pb-2">
              電商網站
            </li>
            <li class="pb-2">
              形象官網
            </li>
            <li class="pb-2">
              第三方 系統 與 API串接
            </li>                  
            <li class="pb-2">
              管理後台
            </li>
          </ul>
          <hr class="horizontal dark my-4">           
          <p>你的產品、你的服務值得最合適的方式來呈現，讓你的客戶不只需要，甚至想要使用。我們有豐富的規劃設計經驗，與傑出的開發團隊，可以精準的幫你把想傳遞的價值交給用戶，為你創造最大價值！</p>
        </div>
        <div class="col-lg-9 col-md-12 position-relative mb-sm-0 mb-3">
          <img
            src="@/assets/img/office-1.jpeg"
            class="w-100 rounded-3 mb-3"
          > 
          <div class="col-12  px-0 mx-auto mt-4 mb-3 order-3 order-md-3 order-lg-3">
            <div class="row d-md-flex justify-content-md-start   m-0">
              <div class="col-logoicon p-0 text-center mb-2">
                <img
                  src="@/assets/img/css.svg"
                  style="object-fit: contain; width: 68px; height: 40px;"
                > 
                <p class="small mt-1">
                  CSS
                </p>
              </div>
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/jQuery.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1 text-nowrap">
                  Jquery
                </p>
              </div>
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/Angular.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  Angular
                </p>
              </div>
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/html.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  HTML5
                </p>
              </div>                
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/vue.webp"
                  class="avatar avatar-40 rounded-0"
                >  <p class="small my-1">
                  Vue.js
                </p>
              </div>                  
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/bootstrap.webp"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1 text-nowrap">
                  Bootstrap
                </p>
              </div>                  
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/restful_api.webp"
                  class="avatar avatar-40 rounded-0"
                >  <p class="small mt-1">
                  RestFule API
                </p>
              </div>                  
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/GraphQL.webp"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1">
                  GraphQL
                </p>
              </div>                    
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/NET_Core.png"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1">
                  net Core
                </p>
              </div>                
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/PHP.png"
                  style="object-fit: contain; width: 68px; height: 40px;"
                >  
                <p class="small my-1 text-nowrap">
                  PHP
                </p>
              </div>
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/msql.png"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  Msql
                </p>
              </div>               
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/MySQL.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  Mysql
                </p>
              </div>
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/Python.webp"
                  style="object-fit: contain; width: 82px; height: 40px;"
                >  
                <p class="small my-1">
                  Python
                </p>
              </div>                
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/goland.webp"
                  class="avatar avatar-40 rounded-0"
                >  <p class="small my-1">
                  Golang
                </p>
              </div>                  
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/expressjs.webp"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1 text-nowrap">
                  Express.js
                </p>
              </div>                  
                
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/Mongodb.webp"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1">
                  MongoDB
                </p>
              </div>                
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/Memcache.png"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1">
                  Memcached
                </p>
              </div>                  
              <div class="col-logoicon  p-0 text-center mb-2">
                <img
                  src="@/assets/img/Redis_Logo.svg"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1">
                  Redis
                </p>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
    <!-- -------- START CONTENT 11 w/ title and 4 images ------- -->
    <div
      class="container mt-md-7 "
      data-aos="fade-down"
      data-aos-duration="700"
      data-aos-delay="300"
      data-aos-once="ture"
      id="d5"
    >
      <div class="row mb-md-7">
        <div class="col-lg-6 col-12 order-2 order-md-1">
          <div class="row">
            <div class="w-50 position-relative mb-sm-0 pt-4">
              <img
                src="@/assets/img/009.jpg"
                class="img-fluid rounded-3 shadow"
              >
            </div>      
            <div class="w-50 position-relative mb-sm-0 pt-4">
              <img
                src="@/assets/img/011.jpg"
                class="img-fluid rounded-3 shadow"
              >
            </div>  
          </div>    
        </div>    
        <div class="col-lg-3 col-md-6 position-relative mb-sm-0 pt-4 order-1 order-md-2">
          <hr class="vertical dark d-lg-block d-none">
          <div class="card-body pt-3 ">
            <h3 class="mb-3 text-gradient text-success">
              IOT 物聯網
            </h3>
            <ul class="nicole-text-name  text-left">
              <li class="pb-2">
                IOT 藍芽整合應用
              </li>
              <li class="pb-2">
                WI-FI 產品整合應用
              </li>
              <li class="pb-2">
                運動與健康管理
              </li>
              <li class="pb-2">
                智能居家
              </li>                  
              <li class="">
                安全監控
              </li>
            </ul>
          </div>
        </div>      
        <div class="col-lg-3 col-12 position-relative mb-sm-0 mb-3 order-3 order-md-3 mt-5 mt-md-0">
          <div class="col-12  px-0 mx-auto mt-4 mb-3 order-3 order-md-3 order-lg-3">
            <div class="row m-0">
              <div class="col-4 p-0 text-center mb-2">
                <img
                  src="@/assets/img/favicon.webp"
                  style="object-fit: contain; width: 68px; height: 40px;"
                > 
                <p class="small mt-1">
                  Line Bot
                </p>
              </div>
              <div class="col-4  p-0 text-center mb-2">
                <img
                  src="@/assets/img/smart-home.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1 text-nowrap">
                  Smart Home
                </p>
              </div>
              <div class="col-4  p-0 text-center mb-2">
                <img
                  src="@/assets/img/AppleSiriIcon2017.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  Apple Siri
                </p>
              </div>
              <div class="col-4  p-0 text-center mb-2">
                <img
                  src="@/assets/img/AWS_Alexa.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  AWS Alexa
                </p>
              </div>                
              <div class="col-4  p-0 text-center mb-2">
                <img
                  src="@/assets/img/google.svg"
                  class="avatar avatar-40 rounded-0"
                >  <p class="small my-1">
                  Google
                </p>
              </div>                  
              <div class="col-4  p-0 text-center mb-2">
                <img
                  src="@/assets/img/Assistant.webp"
                  class="avatar avatar-40 rounded-0"
                >
                <p class="small my-1 text-nowrap">
                  Assistant
                </p>
              </div>                  
              <div class="col-4  p-0 text-center mb-2">
                <img
                  src="@/assets/img/Bluetooth_FM_Color.webp"
                  class="avatar avatar-40 rounded-0"
                >  <p class="small mt-1">
                  Bluetooth
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container  mt-md-7"
      id="d6"
    >
      <div
        class="col-12  position-relative mb-sm-0"
        data-aos="fade-down"
        data-aos-duration="700"
        data-aos-delay="600"
        data-aos-once="ture"
      >
        <div class="row align-items-center">
          <div class="col-12  col-md-5 mb-sm-0">  
            <h3 class="my-3 text-gradient text-primary">
              影音串流
            </h3>
            <ul class="nicole-text-name  text-left">
              <li class="pb-2">
                雲端涵授影音教學平台八年規劃、開發、建置、運營經驗
              </li>
              <li class="pb-2">
                線上直播 / 視訊通話 / 影音串流點播
              </li>
              <li class="pb-2">          
                <a
                  href=""
                  class="text-primary icon-move-right text-18"
                >
                  影音文章互動、離線收聽 Client 端模組
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  />
                </a>
              </li> 
              <li class="pb-2">
                DRM加密：AES 128 / Token / Session
              </li>
              <li class="pb-2">
                Windows端 影片加密/浮水印/解析度/轉檔/上架工具
              </li>
              <li class="pb-2">
                可快速佈署Docker架構，
              </li>          
              <li class="pb-2">
                <a
                  href=""
                  class="text-primary icon-move-right text-18"
                >
                  OltStream 產品介紹
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  />
                </a>
              </li>
            </ul>
            <div class="row d-md-flex  mt-3  m-0">
              <div class="col-2 p-0 text-center mb-2">
                <img
                  src="@/assets/img/WebRtC.webp"
                  style="object-fit: contain; width: 68px; height: 40px;"
                > 
                <p class="small mt-1">
                  WebRtC
                </p>
              </div>
              <div class="col-2  p-0 text-center mb-2">
                <img
                  src="@/assets/img/RTMP.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1 text-nowrap">
                  RTMP
                </p>
              </div>               
              <div class="col-2  p-0 text-center mb-2">
                <img
                  src="@/assets/img/rtsp.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1 text-nowrap">
                  RTSP
                </p>
              </div>
              <div class="col-2  p-0 text-center mb-2">
                <img
                  src="@/assets/img/HLS.webp"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  HLS
                </p>
              </div>
              <div class="col-3  p-0 text-center mb-2">
                <img
                  src="@/assets/img/zoom.png"
                  class="avatar avatar-40 rounded-0"
                >  
                <p class="small my-1">
                  Zoom SDK <br>技術應用與軟體
                </p>
              </div>                  
            </div>
          </div> 
          <div class="col-12 col-md-7">          
            <img
              src="@/assets/img/vivi.jpg"
              class="w-100 rounded-3 mb-3"
            > 
          </div>
        </div>
      </div>
    </div>
<!--
    <div
      class="container mt-5 mt-md-8"
      data-aos="fade-down"
      data-aos-duration="700"
      data-aos-delay="300"
      data-aos-once="ture"
      id="d7"
    >
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 col-12 order-2 order-md-1">
          <img
            src="@/assets/img/test1.jpg"
            class="w-100 rounded-3 mb-3 p-3"
          > 
        </div>

        <div class="col-lg-6 col-12  ps-5 order-1 order-md-2">
          <h3 class="mb-3 text-gradient text-info ">
            雲端出題與考試
          </h3>
          <ul class="nicole-text-name">
            <li class="pb-2">
              八年雲端考試系統規劃、開發、建置、營運經驗
            </li>
            <li class="pb-2">
              後台題庫編輯器：採用 Microsoft Office Word 作為編輯題庫工具
            </li>
            <li class="pb-2">
              領域、科目、年級、單元、節等，彈性化範圍選擇
            </li>
            <li class="pb-2">
              系統自動/教師選擇 出卷模組
            </li>
            <li class="pb-2">
              考試結果匯出系統：匯出 SVN / Image / Word 圖片 Resources
            </li>
            <li class="pb-2">
              跨平台支援支援：Android / IOS / win / Web
            </li>
            <li class="pb-2">
              快速多環境建置：採用 Docker / Restful API 架構
            </li> 
            <li class="pb-2">          
              <a
                href=""
                class="text-info icon-move-right text-18"
              >
                OltExam 產品介紹
                <i
                  class="fas fa-arrow-right text-sm ms-1"
                  aria-hidden="true"
                />
              </a>
            </li> 
          </ul>           
        </div>
      </div>
    </div>
-->
    <div
      class="container  mt-md-7"
      data-aos="fade-down"
      data-aos-duration="700"
      data-aos-delay="300"
      data-aos-once="ture"
    >
      <div class="row">
        <div class="col-12 col-md-6   mx-auto mt-4 mb-3 order-3 order-md-3 order-lg-3  position-relative">
          <hr class="vertical dark d-md-block d-none">
          <h3 class="mb-3 text-gradient text-warning">
            公有雲服務
          </h3>
          <div class="row d-md-flex justify-content-md-start   m-0">
            <div class="col-3 col-md-2 p-0 text-center mb-2">
              <img
                src="@/assets/img/aws.webp"
                style="object-fit: contain; width: 68px; height: 40px;"
              > 
              <p class="small mt-1">
                AWS
              </p>
            </div>
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/googlelogo.webp"
                class="avatar avatar-40 rounded-0"
              >  
              <p class="small my-1 text-nowrap">
                GCP
              </p>
            </div>               
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/Azure.webp"
                class="avatar avatar-40 rounded-0"
              >  
              <p class="small my-1 text-nowrap">
                Azure
              </p>
            </div>
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/oci.png"
                style="object-fit: contain; width: 68px; height: 40px;"
              > 
              <p class="small my-1">
                OCI
              </p>
            </div>
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/k8s.png"
                class="avatar avatar-40 rounded-0"
              >  
              <p class="small my-1">
                K8S
              </p>
            </div>                  
             
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/docker.webp"
                class="avatar avatar-40 rounded-0"
              >  
              <p class="small my-1">
                Docker
              </p>
            </div>                
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/git.webp"
                class="avatar avatar-40 rounded-0"
              >  
              <p class="small my-1">
                Git
              </p>
            </div>   
            <div class="col-3 col-md-2  p-0 text-center mb-2" />   
          </div>
        </div>        
        <div class="col-12 col-md-6 mx-auto mt-4 mb-3 order-3 order-md-3 order-lg-3">
          <h3 class="mb-3 text-gradient text-success">
            私有雲服務
          </h3>
          <div class="row d-md-flex justify-content-md-start   m-0">
            <div class="col-3 col-md-2 p-0 text-center mb-2">
              <img
                src="@/assets/img/vmware.png"
                style="object-fit: contain; width: 68px; height: 40px;"
              > 
              <p class="small mt-1">
                VMware
              </p>
            </div>
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/vsan.jpeg"
                class="avatar avatar-40 rounded-0"
              >  
              <p class="small my-1 text-nowrap">
                VSAN HCI
              </p>
            </div>               
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/cisco.png"
                style="object-fit: contain; width: 68px; height: 40px;"
              >  
              <p class="small my-1 text-nowrap">
                Cisco
              </p>
            </div>
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/Fortigate.png"
                class="avatar avatar-40 rounded-0"
              > 
              <p class="small my-1">
                Fortigate
              </p>
            </div>
            <div class="col-3 col-md-2  p-0 text-center mb-2">
              <img
                src="@/assets/img/vpn-icon.png"
                class="avatar avatar-40 rounded-0"
              >  
              <p class="small my-1">
                VPN
              </p>
            </div>                  
            <div class="col-3 col-md-2  p-0 text-center mb-2" />                                 
          </div>
        </div>
      </div>
    </div>
    <!-- -------- END CONTENT 11 w/ title and 4 images ------- -->
  </section>

  <!-- ---- START FOOTER 4 dark w/ 2 cols ---- -->
  <Footer />



  <!-- ---- END FOOTER 4 dark w/ 2 cols ---- -->
  <nav id="cd-vertical-nav">
    <ul>
      <li>
        <a
          href="#d1"
          data-number="1"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">UI/UX規畫</span>
        </a>
      </li>
      <li>
        <a
          href="#d2"
          data-number="2"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">APP 開發</span>
        </a>
      </li>
      <li>
        <a
          href="#d3"
          data-number="3"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">API 開發</span>
        </a>
      </li>
      <li>
        <a
          href="#d4"
          data-number="4"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">網站設計</span>
        </a>
      </li>
      <li>
        <a
          href="#d5"
          data-number="5"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">IOT 物聯網</span>
        </a>
      </li>
       
      <li>
        <a
          href="#d6"
          data-number="6"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">影音串流</span>
        </a>
      </li>      
      <li>
        <a
          href="#d7"
          data-number="7"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">雲端出題考試</span>
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , Footer } from '@/components/Frame';

export default {
  name: 'App',
  components: {
    SideNav,
    Footer
  },
  setup() {

    const copyrightTime = ref() ;

    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 

    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      copyrightTime
    };
  },
  created() {
  },
  mounted() {
    this.$smoothScroll({
      scrollTo: document.getElementById('header'),
      //hash: '#header' // required if updateHistory is true
    });
  },
  methods: {
  }
};
</script>
