// 物件 ES6 封裝
// ES6 必需 EXPORT 出物件才能被 import 使用
import { restApi } from '@/include/config';
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { oltApiAccess } from '@/lib/oltModule/oltApiAccess';
import { oltRestApiClient } from '@/lib/oltModule/oltRestApiClient';

export class oltApiClient{

  constructor () {
    this.oAA = new oltApiAccess() ; 
  }

  // Vue3 start

  // putRecord , PostRecord , delRecord 的 API Param 
  // Vue3 版 必需將表單的欄位內容帶進來 
  // 跟表單有關的功能 必需用這個 函式 而且 FORM 帶入是必要的 也是強迫性 
  // 這樣可以解決 編譯器為了省記憶體 刪掉不該刪的表單欄位 的問題
  getFormParamVue3(obj , form){
    return this.oAA.getFormParamVue3(obj , form);
  }

  // getOneRecord , getAllRecord 的 API Param 
  // 根據 VUE3 版本 封裝 編譯完問題會比較少，如果發現有 BUG 直接換成它就好了
  getRecordParamVue3(obj){
    return this.oAA.getRecordParamVue3(obj);
  }
  // Vue3 End 



  getApiParam(obj){
    return this.oAA.getApiParam(obj);
  }

  getClientApiParam(obj){
    return this.oAA.getClientApiParam(obj);
  }



  paserFormToForm(obj){
    return this.oAA.paserFormToForm(obj);
  }

  apiParamToField(){

  }




  searchQueryValueMarge(obj){
    return this.oAA.searchQueryValueMarge(obj); 
  }

  queueGetPageRecord(param,queueSpeed) {
    return this.oAA.queueGetPageRecord(param,queueSpeed); 
  }

  queueGetAllRtRecord(apiName,queueSpeed){
    return this.oAA.queueGetAllRtRecord(apiName,queueSpeed); 
  }

  queueRtSetComparisonPageRtRecord(rtSet,recordData){
    return this.oAA.queueRtSetComparisonPageRtRecord(rtSet,recordData); 
  }

  queueGetPageRtRecord(rk,rtCondition , queueSpeed){
    return this.oAA.queueGetPageRtRecord(rk,rtCondition , queueSpeed);
  }


  async queueGetAllRt(obj,param,cb) {
    // fix bug 2021/12/21
    return this.oAA.queueGetAllRt(obj,param,cb);
  }


  async queueGetAll(obj,param,cb) {
    return this.oAA.queueGetAll(obj,param,cb);
  }

  getOneRecord(item,param){
    return this.oAA.getOneRecord(item,param);
  }

  getAllRecord(param, item){
    let orac = new oltRestApiClient();   
    let resObj = orac.getAllRecord(param,item) ;

    return new Promise((resolve, reject) =>{
      resObj
        .then((res)=> {
    			oltConsole.setDebugMode(true);
          oltConsole.log('getAllRecord : ');
          oltConsole.log(res);
          resolve(res);
        })
        .catch((err)=> {
			    reject(err) ;
        });
    }) ; 
  }

  postFileRecord(param, file){
    let orac = new oltRestApiClient();   
    let resObj = orac.postFile(param, file) ;

    return new Promise((resolve, reject) =>{
      resObj
        .then((res)=> {
          resolve(res);
        })
        .catch((err)=> {
			    reject(err) ; 
        });
    }) ; 
  }

  postRecord(param){
    return this.oAA.postRecord(param);
  }

  putRecord(param,item){
    return this.oAA.putRecord(param,item);
  }

  deleteRecord(item,param){
    return this.oAA.deleteRecord(item,param);
  }

  confirmDelete(item){
    return this.oAA.confirmDelete(item);
  }

  doubleConfirmDelete(res,item){
    return this.oAA.doubleConfirmDelete(res,item);
  }
}
