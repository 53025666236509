<template>
  <footer
    class="footer  py-3  bg-gradient-dark position-relative"
    style="overflow: hidden;"
  >
    <img
      src="@/assets/img/shapes/waves-white.svg"
      alt="pattern-lines"
      class="position-absolute start-0 top-0  opacity-6 "
    >
    <div class="container position-relative">
      <div class="row">
        <div class="col-12 col-md-6 me-auto my-auto text-center text-md-left ">
          <div class="d-flex  mb-2 justify-content-center justify-content-md-start align-items-center ">
            <img
              src="@/assets/img/logo-02.svg"
              class="avatar avatar-30 rounded-0 me-2"
            >
            <h6 class="text-white font-weight-bolder text-uppercase mb-0 text-18">
              海煜科技股份有限公司
            </h6>
          </div>
          <p class="text-white mb-0">
            Copyright © {{ copyrightTime }}  by Ocean Light
          </p>
        </div>
        <div class="col-12 col-md-6 ms-md-auto  text-center text-md-right mb-sm-0">
          <p class="text-white  mb-1">
            241新北市三重區重新路五段609巷14號5樓之3
          </p>
          <p class="text-white  mb-1">
            nicole@oceanlighttech.com
          </p>
          <p class="text-white  mb-1">
            02-29959298
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import { oltConsole , oAC , oAuth , oUserInfo} from '@/lib/oltModule/initClient';
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    /* eslint-disable vue/require-default-prop */
    showmode: String,
    tname: String,
    hiddenHeader:Boolean,
    routerBack:Boolean,
    routerName:String,
    routerTitle:String,
    rightbtnname: String,
    onright:Function,
    onleft:Function,
    rightbtnstatus: String,
    righttype: String
  },
  data () {
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';

    const copyrightTime = ref() ;

    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 

    return { 
      //i18n
      t,
      locale,
      // params
      setDebugMode:false , // 該功能的除錯模式
      apiDebug:false , // API 除錯模式
      apiGetPageDebug:false , // API getPageRecord 除錯模式
      apiGetRtDebug:false , // API getRt 除錯模式
      apiGetOneDebug:false , // API getOneRecord 除錯模式
      apiPutDebug:false , // API 修改 除錯模式
      apiPostDebug:false , // API 新增 除錯模式
      apiDeleteDebug:false , // API 刪除 除錯模式
      platform:'client' ,    
      apiName:'user_role_priority' ,   
      apiParam:{
        uid:'',
      },
      conditionType:'pky',  
      pky:{'uid':'user_role_priority' }, 
      rtSet:{},
      record: [],
      totalPage:'',
      session: {},
      recordData: [],
      rtAllRecord:[],
      rtPageRecord:[],
      selectItems:{},
      perPageOptions: [5, 10, 25, 50 , 75 , 100],
      pageTab:0 ,
      mail_total:0,
      view_id:'',
      timer_id:'',
      copyrightTime
    };
  },
  created(){

  },
  mounted(){ 

  },
  methods:{
  },
};
</script>

