/* eslint-disable import/no-unresolved */
import { config } from '@/include/config';
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  //RouteRecordRaw,
} from 'vue-router';
// 第一層 ROUTER 
import Home from '@/views/Home.vue' ; 
import WhatWeDo from '@/views/WhatWeDo.vue' ; 
import HowWeWork from '@/views/HowWeWork.vue' ; 
import WhoToDo from '@/views/WhoToDo.vue' ; 
import SuccessfulCase from '@/views/SuccessfulCase.vue' ; 
import Product from '@/views/Product.vue' ; 
import ProductTest from '@/views/ProductTest.vue' ; 
import ContactUs from '@/views/ContactUs.vue' ; 

// 第二層 Router 
// 系統管理
//import AuthPage from '@/router/AuthPage';
//import SysMs from '@/router/SysMs';

const routes = [
/*
  {
    path: '/',
    redirect: '/Home',
  },
*/
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/WhatWeDo',
    name: 'WhatWeDo',
    component: WhatWeDo,
  },
  {
    path: '/HowWeWork',
    name: 'HowWeWork',
    component: HowWeWork,
  },
  {
    path: '/WhoToDo',
    name: 'WhoToDo',
    component: WhoToDo,
  },
  {
    path: '/SuccessfulCase',
    name: 'SuccessfulCase',
    component: SuccessfulCase,
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/ProductTest',
    name: 'ProductTest',
    component: ProductTest,
  },  
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
  },
  //SysMs,
  //AuthPage,
];
    
const router = createRouter({
  //history: createWebHashHistory(config.base_url),
  //history: createWebHashHistory(),
  //history: createWebHistory(config.base_url),
  history: createWebHistory(),
  linkActiveClass: 'cur',
  linkExactActiveClass: 'cur',  
  routes,
});
    

router.isReady().then(() => {
  console.log('is Ready!!!!');
});

export default router;
