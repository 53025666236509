<template>
  <router-view />
</template>
<script>
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
export default {
  name: 'App',
  components: {
  },
  setup() {
    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
    };
  },
  created() {

    let preLoading = document.getElementById('preLoader');

    if (preLoading !== null) {
      document.body.removeChild(preLoading);
    }
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
.swal2-popup {
  font-size: 0.8rem !important;
}
.swal2-container{
  z-index: 20000;
}
#app .swal2-container{
  z-index: 20000;
}

</style>
<!-- 平板以下載具 用 Body Scroll 下拉效能會比較好  -->
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
<style>

#app .spinner {
  position:relative;
  text-align:center;
  margin:auto;
  padding:0;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #666;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-no-more{
  text-align:center;
  margin:auto;
}
.spinner-no-results{
  text-align:center;
  margin:auto;
}
</style>
<!-- 用不到
<style src="@/assets/css/nucleo-icons.css" />
<style src="@/assets/css/nucleo-svg.css" />
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
-->