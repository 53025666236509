<template>
  <div id="header" />
  <!-- Navbar Transparent -->
  <div class="container position-sticky z-index-sticky top-0 side-nav">
    <div class="col-12">
      <nav class="navbar navbar-expand-lg navbar-light blur blur-rounded top-0  z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-3">
        <div class="container-fluid">
          <a
            class="navbar-brand font-weight-bolder ms-sm-3"
            rel="tooltip"
            title="海煜科技"
            data-placement="bottom"
            href="javascript:;"
            @click="goToPage('/')"
          >
            <img
              src="@/assets/img/logo-01.svg"
              class="avatar avatar-sm rounded-0"
            >
            海煜科技
          </a>
          <button
            class="navbar-toggler shadow-none ms-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navigation"
            aria-controls="navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="nav-button"
          >
            <span class="navbar-toggler-icon mt-2">
              <span class="navbar-toggler-bar bar1" />
              <span class="navbar-toggler-bar bar2" />
              <span class="navbar-toggler-bar bar3" />
            </span>
          </button>
          <div
            class="collapse navbar-collapse  pt-3 pb-2 py-lg-0"
            id="navigation"
          >
            <ul class="navbar-nav navbar-nav-hover mx-auto">
              <li class="nav-item dropdown  mx-2">
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="javascript:;"
                  @click="goToPage('/WhatWeDo')"
                >
                  Service
                </a>
              </li>                
              <li class="nav-item dropdown  mx-2">
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="javascript:;"
                  @click="goToPage('/HowWeWork')"
                >
                  Work
                </a>
              </li>                
              <li class="nav-item dropdown  mx-2">
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="javascript:;"
                  @click="goToPage('/WhoToDo')"
                >
                  About
                </a>
              </li>                
              <li class="nav-item dropdown  mx-2">
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="javascript:;"
                  @click="goToPage('/SuccessfulCase')"
                >
                  Successful Case
                </a>
              </li>                  
              <li class="nav-item dropdown  mx-2">
                <!--
                <a
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="javascript:;"
                  @click="goToPage('/Product')"
                >
                -->
                <a 
                  class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                  href="https://cloudraise.com/Products"
                  target="_blank"
                >
                  Product
                </a>
              </li>
            </ul>
            <ul class="navbar-nav mt-2 mt-md-0">
              <li class="nav-item">
                <a
                  class="btn btn-sm  bg-gradient-info2 text-white btn-round mb-0 me-1"
                  href="javascript:;"
                  @click="goToPage('/ContactUs')"
                >Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import { oltConsole , oAC , oAuth , oUserInfo} from '@/lib/oltModule/initClient';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    /* eslint-disable vue/require-default-prop */
    showmode: String,
    tname: String,
    hiddenHeader:Boolean,
    routerBack:Boolean,
    routerName:String,
    routerTitle:String,
    rightbtnname: String,
    onright:Function,
    onleft:Function,
    rightbtnstatus: String,
    righttype: String
  },
  data () {
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';

    return { 
      //i18n
      t,
      locale,
      // params
      setDebugMode:false , // 該功能的除錯模式
      apiDebug:false , // API 除錯模式
      apiGetPageDebug:false , // API getPageRecord 除錯模式
      apiGetRtDebug:false , // API getRt 除錯模式
      apiGetOneDebug:false , // API getOneRecord 除錯模式
      apiPutDebug:false , // API 修改 除錯模式
      apiPostDebug:false , // API 新增 除錯模式
      apiDeleteDebug:false , // API 刪除 除錯模式
      platform:'client' ,    
      apiName:'user_role_priority' ,   
      apiParam:{
        uid:'',
      },
      conditionType:'pky',  
      pky:{'uid':'user_role_priority' }, 
      rtSet:{},
      record: [],
      totalPage:'',
      session: {},
      recordData: [],
      rtAllRecord:[],
      rtPageRecord:[],
      selectItems:{},
      perPageOptions: [5, 10, 25, 50 , 75 , 100],
      pageTab:0 ,
      mail_total:0,
      view_id:'',
      timer_id:'',
    };
  },
  updated() {
  },
  created(){
  },
  mounted(){ 
    // 強制 重新載入後都要 false 
    document.getElementById('nav-button').setAttribute('aria-expanded', 'false');
  },
  methods:{
    goToPage(page){
      //console.log(page);
      this.$router.push( { path: page } );
    },    
  },
};
</script>

