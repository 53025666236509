
<template>
  <SideNav />
  <!--海煜-->
  <header>
    <div
      class="page-header wrapperpic"
      id="d1"
    >
      <div>
        <video
          class="position-absolute fixed-top ms-auto backvideo h-100 z-index-0  rounded-5 px-0"
          id="video"
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          poster="@/assets/img/back.jpg"
          style="object-fit: cover;"
        ><source
          type="video/mp4"
          src="@/assets/img/02.mp4"
        ></video>
      </div>
      <div class="container">
        <div class="mt-7 mt-md-0 mb-5">
          <div class="col-lg-9 col-xl-7 d-flex">
            <div class="card card-body blur text-left shadow-lg mt-sm-5 p-4  p-sm-5  text-center position-relative">
              <a
                class="me-2 text-gray position-absolute end-0 top-25 d-flex text-nowrap d-none d-md-block"
                style="writing-mode:vertical-rl; "
                data-aos="fade-down"
                data-aos-duration="500"
                data-aos-delay="2000"
                href="#d2"
                v-smooth-scroll
              > Scroll down
                <img
                  data-v-3b0338a9
                  src="@/assets/img/arrow.svg"
                  class="slideDown me-1"
                  style="height: 65px;"
                > 
              </a>
              <div
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-easing="linear"
                class="my-3"
              >
                <img
                  src="@/assets/img/logo.svg"
                  style="height: 60px;"
                >
              </div>
              <div class="my-3 my-md-4 my-lg-5">
                <a
                  href="javascript:;"
                  @click="goToPage('/WhoToDo')"
                >

                  <h4
                    class="text-gradient text-info2 text-bold mb-3"
                    data-aos="zoom-in"
                    data-aos-delay="400"
                    data-aos-duration="500"
                  >
                    資訊整合服務
                  </h4>
                  <div class="col-md-9 mx-auto mt-4 mt-md-5">
                    <h5
                      data-aos="zoom-in"
                      data-aos-delay="700"
                      data-aos-duration="500"
                      class="text-gray text-18 text-left"
                    >
                      海煜科技提供您 APP開發(iOS/Android)、軟硬體整合、IOT 互聯網整合、軟體 UI/UX 設計、公私有雲 Infra 建置維運等服務。
                    </h5>
                  </div>
                </a>
              </div>
              <hr class="horizontal dark mb-0">
              <div class="row px-0  justify-content-center d-none d-md-flex">
                <a
                  class="mt-5 col-sm-2 col-4 p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                  data-aos-duration="400"
                  data-aos-once="true"
                  href="#d2"
                  v-smooth-scroll
                >
                  <i class="far fa-phone-laptop text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">APP開發</h3>
                </a>
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="1200"
                  data-aos-duration="400"
                  href="#hard"
                  v-smooth-scroll
                >
                  <i class="far fa-server text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">軟硬整合</h3>
                </a>
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="1400"
                  data-aos-duration="400"
                  href="#iot"
                  v-smooth-scroll
                >
                  <i class="far fa-chart-network text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">IOT互聯網</h3>
                </a>
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="1600"
                  data-aos-duration="400"
                  href="#uiux"
                  v-smooth-scroll
                >
                  <i class="far fa-pencil-ruler text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">UI/UX設計</h3>
                </a>                
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="1800"
                  data-aos-duration="400"
                  href="#cloud"
                  v-smooth-scroll
                >
                  <i class="far fa-cloud text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">雲端服務</h3>
                </a>
              </div>
              <div class="row px-0  justify-content-center d-md-none">
                <a
                  class="mt-5 col-sm-2 col-4 p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="400"
                  data-aos-once="true"
                  href="#d2"
                  v-smooth-scroll
                >
                  <i class="far fa-phone-laptop text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">APP開發</h3>
                </a>
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="400"
                  href="#hard"
                  v-smooth-scroll
                >
                  <i class="far fa-server text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">軟硬整合</h3>
                </a>
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="400"
                  href="#iot"
                  v-smooth-scroll
                >
                  <i class="far fa-chart-network text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">IOT互聯網</h3>
                </a>
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="800"
                  data-aos-duration="400"
                  href="#uiux"
                  v-smooth-scroll
                >
                  <i class="far fa-pencil-ruler text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">UI/UX設計</h3>
                </a>                
                <a
                  class="mt-5 col-sm-2 col-4  p-0 text-center"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                  data-aos-duration="400"
                  href="#cloud"
                  v-smooth-scroll
                >
                  <i class="far fa-cloud text-logo fa-2x" />
                  <h3 class="text-1816 mt-2 text-gray mb-0">雲端服務</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!--我們能做什麼-->
  <section
    class="pt-md-8 mt-7 "
    id="d2"
  >
    <div class="container-fluid px-0 ">
      <a 
        href="javascript:;"
        @click="goToPage('/WhatWeDo')"
      >
        <div class="col-12 position-relative">
          <div class="col-12 col-xxl-3 mx-auto mb-5 text-center">
            <h2 class=" text-gradient text-warning  text-bold text-3426">我們能做什麼 </h2>
            <h3 class="text-muted  text-bold text-3426">Work with an amazing <span
              class="text-muted"
              id="typed"
            /></h3>
            <div id="typed-strings">
              <h4>team</h4>
              <h4>design</h4>
              <h4>tool</h4>
            </div>
            <img
              class="w-10 end-10 position-absolute mt-n6"
              src="@/assets/img/pattern-points.png"
              alt="image"
            >
          </div>
        </div>
      </a>
      <div class=" bg-info-soft">
        <div class="section section-products"> 
          <div class="points"> 
            <div class="point point-floating-3" /> 
            <div class="point point-floating-4" /> 
            <div class="point point-floating-5" /> 
          </div> 
          <div class="row mx-0">  
            <div class="col-12 col-md-11 col-lg-7  col-xl-6 col-xxl-5 position-relative padcut"> 
              <div class="ipad"> 
                <div class="shadow" /> 
                <div class="lid" /> 
                <div class="screen"> 
                  <div
                    id="carouselExampleIndicators"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <ol class="carousel-indicators">
                      <li
                        data-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        class="active"
                      />
                      <li
                        data-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                      />
                      <li
                        data-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                      />
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img
                          class="d-block w-100"
                          src="@/assets/img/IMGEN2.jpg"
                          alt="First slide"
                        >
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="@/assets/img/web1.jpg"
                          alt="Second slide"
                        >
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="@/assets/img/tvbox.jpg"
                          alt="Third slide"
                        >
                      </div>
                    </div>
                    <a
                      class="carousel-control-prev"
                      href="#carouselExampleIndicators"
                      role="button"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span class="sr-only">Previous</span>
                    </a>
                    <a
                      class="carousel-control-next"
                      href="#carouselExampleIndicators"
                      role="button"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div> 
              </div> 
            </div>
            <div class="height-800 d-none d-md-block d-lg-none" />                                                                                                                                                               
            <div class="col-12 col-lg-5 col-xl-6 col-xxl-7 my-lg-9">
              <div class="text-left border-radius-lg">
                <div
                  class="row align-items-center justify-content-center mx-0"
                  href="javascript:;"
                  @click="goToPage('/WhatWeDo')"                  
                >
                  <div class="text-center text-md-right col-12 col-md-4 me-3 me-md-5">
                    <h4 class="nicole-text-title text-simbold">
                      APP開發
                    </h4>
                  </div>
                  <div class="col-12 col-md-7">
                    <ul class="nicole-text-time my-3 ">
                      <li class="pb-4 nicole-text-time">
                        iOS 與 Android雙平台開發
                      </li>
                      <li class="pb-4 nicole-text-time">
                        行動推播通知
                      </li>
                      <li class="pb-4 nicole-text-time">
                        行動APP後台管理系統
                      </li>    
                      <li class="pb-4 nicole-text-time">
                        經濟部工業局「行動應用App基本資安檢測基準」L1/L2/L3檢驗
                      </li>
                    </ul>
                  </div>
                </div>
              </div>                                           
            </div> 
          </div> 
          <div class="space-180 d-none d-md-block" /> 
          <div class="points"> 
            <div class="point point-floating-3" /> 
            <div class="point point-floating-4" /> 
            <div class="point point-floating-5" /> 
            <div class="point point-floating-7" /> 
            <div class="point point-floating-8" /> 
          </div> 
          <div
            class="row align-items-center justify-content-center mx-0"
            id="hard"
          >
            <div class="col-12 col-lg-6 order-2 order-md-2  order-xl-2 order-xl-1">
              <div class="text-center text-md-left border-radius-lg">
                <div class="row align-items-center justify-content-center mx-0">
                  <div class="text-center text-md-right col-12 col-md-5 me-3 me-md-5">
                    <h5 class="nicole-text-title text-simbold">
                      軟硬體整合
                    </h5>
                  </div>
                  <div class="col-12 col-md-6">
                    <ul class="nicole-text-time my-3 text-left">
                      <li class="pb-4 nicole-text-time">
                        IOT藍牙整合應用
                      </li>
                      <li class="pb-4 nicole-text-time">
                        軟硬體產品整合應用
                      </li>
                      <li class="pb-4 nicole-text-time">
                        遠端視訊串流服務
                      </li>
                      <li class="pb-4 nicole-text-time">
                        無線傳輸技術支援
                      </li>       
                    </ul>
                  </div>
                </div>
              </div>
            </div>     
            <div class="col-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4  order-1 order-md-1 order-lg-2">
              <img
                class="w-100  px-md-0 py-5 py-md-7  py-lg-10 "
                src="@/assets/img/iphone.png"
                alt="image"
              >
            </div>
          </div>
          <div class="space-180 d-none d-md-block" />
          <div
            class=""
            id="iot"
          >
            <div class="row align-items-center justify-content-center mx-0">
              <div class="col-12  col-lg-6 col-xl-5 col-xxl-4">
                <img
                  class="w-100  py-5 py-md-7 py-lg-9"
                  src="@/assets/img/iot1.png"
                  alt="image"
                >
              </div>
              <div class="col-lg-6">
                <div class="text-center text-md-left border-radius-lg">
                  <div class="row align-items-center justify-content-center mx-0">
                    <div class="text-center text-md-right col-12 col-md-5 me-3 me-md-5">
                      <h5 class="nicole-text-title text-simbold">
                        IOT互聯網
                      </h5>
                    </div>
                    <div class="col-12 col-md-6">
                      <ul class="nicole-text-time my-3 text-left">
                        <li class="pb-4">
                          IOT藍牙整合應用
                        </li>
                        <li class="pb-4">
                          無線應用整合
                        </li>
                        <li class="pb-4">
                          感測器應用整合
                        </li>
                        <li class="pb-4">
                          遠端視訊串流服務
                        </li>       
                      </ul>
                    </div>
                  </div>
                </div>                                                                   
              </div>
            </div>
          </div>
          <div
            id="uiux"
            class="space-180 d-none d-md-block"
          />
          <div class="points"> 
            <div class="point point-floating-6" /> 
            <div class="point point-floating-2" /> 
            <div class="point point-floating-5" /> 
            <div class="point point-floating-7" /> 
            <div class="point point-floating-8" /> 
            <div class="point point-floating-9" /> 
          </div>
          <div class="row mx-0">
            <div class="col-lg-6 my-lg-8 order-2 order-lg-1">         
              <div class="col-12 my-3  px-xxl-5">
                <div class="text-center text-md-left border-radius-lg">
                  <div class="row align-items-center justify-content-center mx-0">
                    <div class="text-center text-md-right col-12 col-md-5 me-3 me-md-5">
                      <h5 class="nicole-text-title me-3 text-simbold">
                        UI/UX設計
                      </h5>
                    </div>
                    <div class="col-12 col-md-6">
                      <ul class="nicole-text-time my-3 text-left">
                        <li class="pb-4 nicole-text-time">
                          iOS 與 Android雙平台開發
                        </li>
                        <li class="pb-4 nicole-text-time">
                          行動推播通知
                        </li>
                        <li class="pb-4 nicole-text-time">
                          社群API整合
                        </li>
                        <li class="pb-4 nicole-text-time">
                          行動APP後台管理系統
                        </li>       
                      </ul>
                    </div>
                  </div>                                                                
                </div>
              </div> 
            </div> 
            <div
              class="col-12 col-md-12 col-lg-6 position-relative order-1 order-lg-2 hidelab"
              style=""
            > 
              <div class="laptop"> 
                <div class="shadow" /> 
                <div class="lid" /> 
                <div class="screen"> 
                  <div
                    id="mac"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <ol class="carousel-indicators">
                      <li
                        data-target="#mac"
                        data-bs-slide-to="0"
                        class="active"
                      />
                      <li
                        data-target="#mac"
                        data-bs-slide-to="1"
                      />
                      <li
                        data-target="#mac"
                        data-bs-slide-to="2"
                      />
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img
                          class="d-block w-100"
                          src="@/assets/img/web6.jpg"
                          alt="First slide"
                        >
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="@/assets/img/card2.jpg"
                          alt="Second slide"
                        >
                      </div>
                      <div class="carousel-item">
                        <img
                          class="d-block w-100"
                          src="@/assets/img/east.jpg"
                          alt="Third slide"
                        >
                      </div>
                    </div>
                    <a
                      class="carousel-control-prev"
                      href="#mac"
                      role="button"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span class="sr-only">Previous</span>
                    </a>
                    <a
                      class="carousel-control-next"
                      href="#mac"
                      role="button"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div> 
                <div class="bottom"> 
                  <div class="keyboard" /> 
                  <div class="trackpad" /> 
                </div> 
              </div> 
            </div> 
        
            <div class="points"> 
              <div class="point point-floating-1" /> 
              <div class="point point-floating-3" /> 
              <div class="point point-floating-4" /> 
            </div> 
          </div> 
          <div class="space-180 d-none d-md-block" />
          <div
            class="row align-items-center justify-content-center mx-0"
            id="cloud"
          >
            <div class="col-12 col-md-10 col-lg-6 col-xl-4 col-xxl-3 order-1 order-md-1 order-lg-2">
              <img
                class="w-100 pb-5 py-md-7 py-lg-9 "
                src="@/assets/img/cards-test.png"
                alt="image"
              >
            </div>
            <div class="col-lg-6  order-2 order-md-2  order-xl-2 order-xl-1 mt-md-5 mt-lg-0">
              <div class="text-center text-md-left border-radius-lg">
                <div class="row align-items-center justify-content-center mx-0 ">
                  <div class="text-center text-md-right col-12 col-md-5 me-3 me-md-5">
                    <h5 class="nicole-text-title text-simbold">
                      雲端服務
                    </h5>
                  </div>
                  <div class="col-12 col-md-6">
                    <ul class="nicole-text-time my-3 text-left">
                      <li class="pb-4 nicole-text-time">
                        雲端平台程式開發
                      </li>
                      <li class="pb-4 nicole-text-time">
                        OTA 韌體自動更新
                      </li>
                      <li class="pb-4 nicole-text-time">
                        資料庫建置與開發
                      </li>
                      <li class="pb-4 nicole-text-time">
                        Client / Server開發
                      </li>       
                      <li class="pb-4 nicole-text-time">
                        私有雲建置維運
                      </li>       
                      <li class="pb-4 nicole-text-time">
                        容器化架構建置維運
                      </li>       
                    </ul>
                  </div>
                </div>
              </div>
            </div>                                                                   
          </div> 
          <div class="space-180 d-none d-md-block" /> 
        </div>
      </div>
    </div>
  </section>
  <!--我們如何做-->
  <section
    class="pt-6 pt-lg-10  pb-8  pb-md-10 pb-lg-12"
    id="d3"
  >
    <div class="container">
      <div class="d-lg-flex align-items-center justify-content-center">
        <div
          class=" col-12 col-lg-5  mt-5 "
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="300"
        >
          <h3 class="text-gradient text-primary text-bold text-3426">
            我們如何做
          </h3>
          <h3>How We work</h3>
          <p class="nicole-text-name">
            傳統軟體開發專案的成功機率不到三成，導致市場充斥著失敗的產品與無法收尾的專案。海煜使用敏捷開發搭配模組化架構，大幅增加開發的製作效率和良率，提供客戶具有完善價值的產品。
          </p>
          <a
            href="javascript:;"
            @click="goToPage('/HowWeWork')"
            class="text-primary icon-move-right text-18"
          >詳細了解
            <i
              class="fas fa-arrow-right text-sm ps-1"
              aria-hidden="true"
            />
          </a>
        </div>
        <div class="col-1">
	      &nbsp;
        </div>
        <div class="col-12 col-md-9 col-lg-7  text-center mx-auto mt-5 mt-lg-0">
          <img
            src="@/assets/img/presentation-ipad.jpg"
            class="w-100 pe-5 pe-md-7"
          >
          <div class="text-right mt-n7 mt-md-n8 mt-lg-n9 mt-xl-n10">
            <img
              src="@/assets/img/ipad-comments.jpg"
              class="w-40 "
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-delay="500"
              data-aos-duration="300"
            >
          </div>
          <div class="text-left  mt-n26">
            <img
              src="@/assets/img/ipad-table.jpg"
              class="w-15"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-delay="300"
              data-aos-duration="300"
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--誰負責做-->
  <section
    class="bg-gradient-dark position-relative overflow-hidden pt-6"
    id="d4"
  >
    <div class="position-absolute w-100 z-inde-1 top-0 mt-n2">
      <svg
        width="100%"
        viewBox="0 -2 1920 157"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>wave-down</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Artboard"
            fill="#FFFFFF"
            fill-rule="nonzero"
          >
            <g id="wave-down">
              <path
                d="M0,60.8320331 C299.333333,115.127115 618.333333,111.165365 959,47.8320321 C1299.66667,-15.5013009 1620.66667,-15.2062179 1920,47.8320331 L1920,156.389409 L0,156.389409 L0,60.8320331 Z"
                id="Path-Copy-2"
                transform="translate(960.000000, 78.416017) rotate(180.000000) translate(-960.000000, -78.416017) "
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <img
      src="@/assets/img/shapes/waves-white.svg"
      class="position-absolute opacity-6  w-100 top-0 d-md-block d-none"
      alt=""
    >
    <div class="container pt-5 pt-md-9 position-relative z-index-3">
      <div class="row">          
        <div
          class="col-lg-7  text-center mt-4 mx-auto"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="700"
        >
          <!--
          <span class="badge badge-white text-dark mb-4">Accountable</span>
          <h2 class="text-white text-bold text-3426 mb-3">
            誰負責製作
          </h2>
          <h5 class="text-white nicole-text-name lh-lg mb-4 text-md-left">
            我們是一群對資訊技術有濃厚熱忱的資深資訊人所創立組成的團隊，經歷過資安業、遊戲業、數位教育業，並分別擔任過遊戲與數位教育業上市櫃專業經理人，超過五位以上的核心成員累積十年以上共事革命情感與合作經驗，提供客戶更周全的技術建議和完善的服務。
          </h5>
          -->
          <a
            href="javascript:;"
            @click="goToPage('/WhoToDo')"            
            class="text-info icon-move-right text-20"
          >Who To Do 
            <i
              class="fas fa-arrow-right text-sm ps-1"
              aria-hidden="true"
            />
          </a>
        </div>
      </div>
      <!--
      <div class="row mt-8">
        <div
          class="col-md-4 mb-md-0 mb-7"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="300"
          data-aos-once="true"
        >
          <div class="card">
            <div class="text-center mt-n5 z-index-1">
              <div class="position-relative">
                <div class="blur-shadow-avatar">
                  <div class="avatar avatar-xl shadow-lg bg-gradient-warning">
                    <i class="far fa-project-diagram text-35 text-white " />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <h4 class="">
                Product Owner
              </h4>
              <p>產品規劃團隊</p>
              <p class="mt-2">
                需求規劃 / 定義範疇 / 時程掌控
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 mb-md-0 mb-7"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-delay="200"
          data-aos-duration="300"
          data-aos-once="true"
        >
          <div class="card">
            <div class="text-center mt-n5 z-index-1">
              <div class="position-relative">
                <div class="blur-shadow-avatar">
                  <div class="avatar avatar-xl shadow-lg bg-gradient-info">
                    <i class="far fa-drafting-compass text-35 text-white " />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <h4 class="">
                Development Team
              </h4>
              <p>開發團隊</p>
              <p class="mt-2">
                研發團隊 / 美術團隊 / 品管團隊
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 mb-md-0 mb-7"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-delay="400"
          data-aos-duration="300"
          data-aos-once="true"
        >
          <div class="card">
            <div class="text-center mt-n5 z-index-1">
              <div class="position-relative">
                <div class="blur-shadow-avatar">
                  <div class="avatar avatar-xl shadow-lg bg-gradient-success">
                    <i class="far fa-briefcase text-35 text-white " />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <h4 class="">
                Business team
              </h4>
              <p>業務部門</p>
              <p>業務開發 / 需求訪談 </p>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
    <div
      class="position-absolute w-100 bottom-0"
      id="d5"
    >
      <svg
        width="100%"
        viewBox="0 -1 1920 166"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>wave-up</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Artboard"
            transform="translate(0.000000, 5.000000)"
            fill="#FFFFFF"
            fill-rule="nonzero"
          >
            <g
              id="wave-up"
              transform="translate(0.000000, -5.000000)"
            >
              <path
                d="M0,70 C298.666667,105.333333 618.666667,95 960,39 C1301.33333,-17 1621.33333,-11.3333333 1920,56 L1920,165 L0,165 L0,70 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </section>
  <!--開發案例-->
  <div class="page-header section-height-85 mt-5 mt-lg-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 my-auto">
          <h1 class="text-gradient text-warning text-bold text-3426">
            開發案例
          </h1>
          <h1 class="mb-4 text-muted nicole-text-title2 text-500">
            Experiences
          </h1>
          <p class="lead ">
            海煜科技累積多年整合經驗， 我們用心的發現每一個客戶面臨的問題， 不停根據整合經驗強化我們的服務， 對企業客戶推出具特色的設計方案。
          </p>
          <div class="buttons">
            <button
              type="button"
              class="btn btn-lg  text-16 bg-gradient-warning mt-4"
              href="javascript:;"
              @click="goToPage('/SuccessfulCase')"              
            >
              Collection<i class="fal fa-long-arrow-right ms-3" />
            </button>
          </div>
        </div>
        <!-- 網頁版-->
        <div
          class="col-lg-8 ps-md-5 pe-0 d-none d-lg-block"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="700"
        >
          <div class="row">
            <div class="col-lg-3 col-6 rotating-card-container mt-7">
              <div class="card card-rotate card-background">
                <div class="front front-background">
                  <img
                    class="w-100 border-radius-lg shadow"
                    src="@/assets/img/006-2.jpg"
                  >
                </div>
                <div class="back back-background">
                  <img
                    class="w-100 border-radius-lg shadow"
                    src="@/assets/img/006.jpg"
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="rotating-card-container ">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/005.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/005-2.jpg"
                      alt=""
                    >
                  </div>
                </div>                
              </div>
              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/012.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/012-2.jpg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="rotating-card-container ">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/004.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/004-2.jpg"
                      alt=""
                    >
                  </div>
                </div>                
              </div>
              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/009.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/009-2.jpg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6 mt-4">
              <div class="rotating-card-container ">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/011.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/011-2.jpg"
                      alt=""
                    >
                  </div>
                </div>                
              </div>
              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/010.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/010-2.jpg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--行動版-->
        <div
          class="col-lg-8  d-lg-none my-5"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <div class="row p-0 m-0">
            <div class="col-lg-3 col-6">
              <div class="rotating-card-container ">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/005.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/005-2.jpg"
                      alt=""
                    >
                  </div>
                </div>                
              </div>
              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/012.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/012-2.jpg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/011.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/011-2.jpg"
                      alt=""
                    >
                  </div>
                </div>                
              </div>
              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/010.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/010-2.jpg"
                      alt=""
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="rotating-card-container ">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/004.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/004-2.jpg"
                      alt=""
                    >
                  </div>
                </div>                
              </div>
              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">              
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/009.jpg"
                      alt=""
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/009-2.jpg"
                      alt=""
                    >
                  </div>
                </div>
              </div>

              <div class="rotating-card-container mt-4">
                <div class="card card-rotate card-background">
                  <div class="front front-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/006-2.jpg"
                    >
                  </div>
                  <div class="back back-background">
                    <img
                      class="w-100 border-radius-lg shadow"
                      src="@/assets/img/006.jpg"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="height-100"
    id="d8"
  />    
  <!--合作夥伴-->
  <!--
  <div class="pb-5 ">
    <div class="container">
      <div class="row">
        <div class="col-8 mx-auto text-center">
          <h2 class="text-gradient text-info  text-bolder">
            合作夥伴
          </h2>
          <h1 class="mb-0 text-muted nicole-text-title2 text-500">
            CLIENTS
          </h1>
        </div>
      </div>
      <div class="row mt-5 align-items-center">
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <a
            href="https://Cloudraise.com"
            target="_blank"
          >
            <img
              class="img-fluid p-md-3"
              src="@/assets/img/CloudRaiseLogo4.svg"
            >
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/q.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/bank1.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/bank2.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/edu.jpg"
          >
        </div>  

        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/khotel.jpg"
          >
        </div> 
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/gotrain.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/pai.jpg"
          >
        </div> 
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/water.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/lt.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/sb.jpg"
          >
        </div>   
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/tact.jpg"
          >
        </div>       
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/david.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/tooth.jpg"
          >
        </div> 

        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid p-md-3"
            src="@/assets/img/li.jpg"
          >
        </div>
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid "
            src="@/assets/img/eng.jpeg"
            style="height: 90px;"
          >
        </div>          
        <div class="col-lg-3 col-md-4 col-6 mb-6 text-center">
          <img
            class="img-fluid "
            src="@/assets/img/x280.webp"
            style="height: 80px;"
          >
        </div>
      </div>
    </div>
  </div>
  -->
  <div
    class="height-100"
    id="d7"
  />
  <!--聯絡我們-->
  <div class="w-100 ">
    <div class="index_giants py-6 py-lg-10 ">      
      <div class="container ">
        <h3 class="text-gradient text-primary text-bolder text-3426">
          Let’s Talk About Your <span
            class="text-gradient text-primary text-bolder"
            id="typed2"
          />
        </h3>
        <div id="typed2-strings">
          <h3>Project.</h3>
          <h3>Design.</h3>
          <h3>Idea.</h3>
        </div>
        <h4 class="text-dark mb-4 nicole-text-title2">
          實現您的創意
        </h4>
            
        <p class="text-dark mb-2 nicole-text-name">
          請告訴我們你的需求，將會有專人儘速和您聯繫。
        </p>
        <p class="text-dark mb-0 nicole-text-name">
          馬上點擊「聯絡我們」，或透過電話與我們聯繫：02-29959298
        </p>
        <div class="col-sm-4 col-6 ps-sm-0 mt-4">
          <button
            type="button"
            class="btn btn-lg  bg-gradient-primary  text-18  mt-3 text-nowrap"
            href="javascript:;"
            @click="goToPage('/ContactUs')"            
          >
            聯絡我們<i class="fal fa-long-arrow-right ms-3" />
          </button>
        </div>
      </div>
    </div>
  </div>



  <!--footer--><!--產品介紹-->
  <div class="position-relative mt-8  z-index-3">
    <section id="d6">
      <div class="container">
        <div
          class="col-lg-6 mx-auto "
          data-aos="zoom-in"
          data-aos-duration="600"
        >
          <div class="p-3 text-center mb-3">
            <h3 class="mt-3 text-gradient text-info mt-5 text-bold text-3426">
              產品介紹
            </h3>
            <h1 class="mb-0 text-muted nicole-text-title2 text-500 ">
              Ocean Light Product
            </h1>
          </div>
        </div>
        <!--
        <a
          class="d-md-flex mb-5"
          href="javascript:;"
          @click="goToPage('/Product')"          
        >
        -->
        <a 
          class="d-md-flex mb-5"
          href="https://cloudraise.com/Products" 
          traget="_blank"
        >
          <div
            class="col-12 col-lg-6 col-md-6  pe-md-3"
            data-aos="zoom-in"
            data-aos-duration="700"
          >
            <div class="card card-background align-items-start h-md-100 move-on-hover">
              <div
                class="full-background"
                style="background-image: url(assets/img/blueclass.jpg)"
              />
              <div class="card-body z-index-3">
                <div class="icon">
                  <i class="fad fa-waveform-path text-35 text-white" />
                </div>
              </div>
              <div class="card-footer pb-3 pt-2 z-index-3">
                <h2 class="text-white mb-1">CrStream Platform</h2>
                <p class="text-white font-weight-bold text-uppercase opacity-8 text-20">影音串流系統 ， 支援 Docker 環境，可佈建公有雲及私有雲。
                </p>
              </div>
              <span class="mask bg-gradient-info border-radius-xl z-index-2 opacity-6" />
            </div>
          </div>

          <div
            class="col-12 col-lg-6 col-md-6 d-flex flex-column ps-md-2 mt-3 mt-md-0"
            data-aos="zoom-in"
            data-aos-duration="700"
          >
            <div class="card card-background align-items-start move-on-hover">
              <div
                class="full-background"
                style="background-image: url(assets/img/read.jpg)"
              />
              <div class="card-body z-index-3">
                <div class="icon">
                  <i class="fad fa-graduation-cap text-35 text-white" />
                </div>
              </div>
              <div class="card-footer pb-3 pt-5 z-index-3">
                <h2 class="text-white mb-1">TronEdu Exam Platform</h2>
                <p class="text-white text-xs font-weight-bolder text-uppercase opacity-8 text-20">題庫考試系統，支援 Docker 環境，可佈建公有雲及私有雲。
                </p>
              </div>
              <span class="mask bg-gradient-warning border-radius-xl z-index-2 opacity-6" />
            </div>

            <div class="card card-background mt-3 align-items-start move-on-hover">
              <div
                class="full-background"
                style="background-image: url(assets/img/office-1.jpeg)"
              />
              <div class="card-body z-index-3">
                <div class="icon">
                  <i class="fad fa-rocket-launch text-white text-35" />
                </div>
              </div>
              <div class="card-footer pb-3 pt-2 z-index-3">
                <h2 class="text-white mb-1">TronMap BMMMS Platform</h2>
                <p class="text-white text-xs font-weight-bolder text-uppercase  opacity-8 text-20">園區建築繪圖與園區建築管理系統，支援 Docker 環境，可佈建公有雲及私有雲</p>
              </div>
              <span class="mask bg-gradient-light border-radius-xl z-index-2 opacity-6" />
            </div>
          </div>
        </a>
      </div>
    </section>   
  </div>
  <Footer class="footer pb-5 pt-12 bg-light mt-n10 position-relative">
    <div class="position-absolute w-100 z-inde-1 top-0 mt-n3">
      <svg
        width="100%"
        viewBox="0 -2 1920 157"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>wave-down</title>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Artboard"
            fill="#FFFFFF"
            fill-rule="nonzero"
          >
            <g id="wave-down">
              <path
                d="M0,60.8320331 C299.333333,115.127115 618.333333,111.165365 959,47.8320321 C1299.66667,-15.5013009 1620.66667,-15.2062179 1920,47.8320331 L1920,156.389409 L0,156.389409 L0,60.8320331 Z"
                id="Path-Copy-2"
                transform="translate(960.000000, 78.416017) rotate(180.000000) translate(-960.000000, -78.416017) "
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 me-auto my-auto text-center text-md-left ">
          <div class="d-flex  mb-2 justify-content-center justify-content-md-start align-items-center ">
            <img
              src="@/assets/img/logoblue.png"
              class="avatar avatar-36 rounded-0 me-2"
            >
            <h6 class="text-info-logo font-weight-bolder text-uppercase mb-0 text-18">
              海煜科技股份有限公司
            </h6>
          </div>
          <p class="text-gray mb-0">
            Copyright © {{ copyrightTime }}  by Ocean Light Technology
          </p>
        </div>
        <div class="col-12 col-md-6 ms-md-auto  text-center text-md-right mb-sm-0">
          <p class="mb-1">
            241新北市三重區重新路五段609巷14號5樓之3
          </p>
          <p class="mb-1">
            nicole@oceanlighttech.com
          </p>
          <p class="">
            02-29959298
          </p>
        </div>
      </div>
    </div>
  </Footer>
    

  <nav id="cd-vertical-nav">
    <ul>
      <li>
        <a
          href="#d1"
          data-number="1"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">海煜科技</span>
        </a>
      </li>
      <li>
        <a
          href="#d2"
          data-number="2"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">What做什麼</span>
        </a>
      </li>
      <li>
        <a
          href="#d3"
          data-number="3"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">How如何做</span>
        </a>
      </li>
      <li>
        <a
          href="#d4"
          data-number="4"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">Who誰來做</span>
        </a>
      </li>
      <li>
        <a
          href="#d5"
          data-number="5"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">開發案例</span>
        </a>
      </li>
       
      <li>
        <a
          href="#d8"
          data-number="6"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">合作夥伴</span>
        </a>
      </li>      
      <li>
        <a
          href="#d7"
          data-number="7"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">聯絡我們</span>
        </a>
      </li>
      <li>
        <a
          href="#d6"
          data-number="6"
          v-smooth-scroll
        >
          <span class="cd-dot" />
          <span class="cd-label">產品介紹</span>
        </a>
      </li> 
    </ul>
  </nav> 
</template>
<script>
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , NavBar , Settings , Footer } from '@/components/Frame';

export default {
  name: 'App',
  components: {
    SideNav,
  },
  setup() {

    const copyrightTime = ref() ;

    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 


    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      copyrightTime
    };
  },
  created() {
  },
  mounted() {

    if (document.getElementById('typed')) {

      /* eslint-disable */
      var typed = new Typed('#typed', {
        stringsElement: '#typed-strings',
        typeSpeed: 110,
        backSpeed: 110,
        backDelay: 400,
        startDelay: 500,
        loop: true
      });
    }

    if (document.getElementById('typed2')) {
      var typed = new Typed('#typed2', {
        stringsElement: '#typed2-strings',
        typeSpeed: 110,
        backSpeed: 110,
        backDelay: 400,
        startDelay: 500,
        loop: true
      });
    }
    /* eslint-enable */
    this.$smoothScroll({
      scrollTo: document.getElementById('header'),
      //hash: '#header' // required if updateHistory is true
    });


  },
  methods: {

    goToPage(page){
      console.log(page);
      this.$router.push( { path: page } );
    },        
  }
};
</script>
