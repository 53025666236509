
<template>
  <SideNav />

 
  <section class="pb-3 pb-md-6  position-relative">
    <div
      class="page-header section-height-50 "
      style="background-image: url(assets/img/curved-images/curved31.jpg);"
    >
      <span class="mask bg-gradient-dark opacity-2" />
      <div
        class="container"
        data-aos="zoom-in"
        data-aos-duration="600"
      >
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center mx-auto">
            <h1 class="text-white mt-6 mt-md-4 mb-1 nicole-text-title">
              How to work
            </h1>
            <p class="nicole-text-name text-white mb-6">
              We&#39;d like to talk more about what you need
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <div class="container">
      <div class="row mt-n7 blur border-radius-lg shadow-blur text-center mx-0">
        <a
          class="col-4 col-lg-2 position-relative my-auto d-flex justify-content-center align-items-center"
          data-aos="fade-right"
          data-aos-duration="600"
          href="#before"
          v-smooth-scroll
        >
          <div class="col-10 py-4 py-lg-5 text-center">
            <i class="fas fa-tasks nicole-text-title3" />    
            <p class="mt-3 mb-0 nicole-text-time text-bold">Initiating</p>
            <p class="mb-0 text-1614">專案開始前</p>
          </div>
          <div class="col-2">
            <i class="fal nicole-text-title3 fa-angle-right  mx-2" />
          </div>
        </a>
        <a
          class="col-4 col-lg-2 position-relative my-auto d-flex align-items-center"
          data-aos="fade-right"
          data-aos-delay="200"
          data-aos-duration="600"
          href="#start"
          v-smooth-scroll
        >
          <div class="col-10 py-4 py-lg-5 text-center">
            <i class="far fa-pencil-ruler nicole-text-title3" />    
            <p class="mt-3 mb-0 nicole-text-time text-bold">Planning</p>
            <p class="mb-0 text-1614">專案開始</p>         
          </div>
          <div class="col-2">
            <i class="fal nicole-text-title3 fa-angle-right  mx-2" />
          </div>
        </a>
        <a
          class="col-4 col-lg-2 position-relative my-auto d-flex align-items-center"
          data-aos="fade-right"
          data-aos-delay="400"
          data-aos-duration="600"
          href="#ing"
          v-smooth-scroll
        >
          <div class="col-10 py-4 py-lg-5 text-center">
            <i class="far fa-laptop-code  nicole-text-title3" />
            <p class="mt-3 mb-0 nicole-text-time text-bold">Executing</p>
            <p class="mb-0 text-1614">專案進行</p>
          </div>
          <div class="col-2  d-none d-lg-block">
            <i class="fal nicole-text-title3 fa-angle-right  mx-2" />
          </div>
        </a>  
        <a
          class="col-4 col-lg-2 position-relative my-auto d-flex align-items-center"
          data-aos="fade-right"
          data-aos-delay="600"
          data-aos-duration="600"
          href="#it"
          v-smooth-scroll
        >
          <div class="col-10 py-4 py-lg-5 text-center">
            <i class="far fa-tachometer-alt-fastest nicole-text-title3" />
            <p class="mt-3 mb-0 nicole-text-time text-bold">IT/ST</p>
            <p class="mb-0 text-1614">整合/系統測試</p>
          </div>
          <div class="col-2">
            <i class="fal nicole-text-title3 fa-angle-right  mx-2" />
          </div>
        </a>        
        <a
          class="col-4 col-lg-2 position-relative my-auto d-flex justify-content-center align-items-center"
          data-aos="fade-right"
          data-aos-delay="800"
          data-aos-duration="600"
          href="#test"
          v-smooth-scroll
        >
          <div class="col-10 py-4 py-lg-5 text-center">
            <i class="far fa-check-double nicole-text-title3" />
            <p class="mt-3 mb-0 nicole-text-time text-bold">UAT</p>
            <p class="mb-0 text-1614">驗收測試 </p>
          </div>
          <div class="col-2">
            <i class="fal nicole-text-title3 fa-angle-right  mx-2" />
          </div>
        </a>
        <a
          class="col-4 col-lg-2 position-relative my-auto d-flex justify-content-center align-items-center"
          data-aos="fade-right"
          data-aos-delay="1000"
          data-aos-duration="600"
          href="#close"
          v-smooth-scroll
        >
          <div class="col-10 py-4 py-lg-5 text-center">
            <i class="far fa-thumbs-up nicole-text-title3" />        
            <p class="mt-3 mb-0 nicole-text-time text-bold">Close</p>
            <p class="mb-0 text-1614">結束專案</p>
          </div>

        </a>
      </div>
    </div>
  </section>
    
    

  <div class="  pb-5">
    <div class="container ">
      <div
        class="mb-3 mb-md-5"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
        id="before"
      >
        <div class="card shadow-sm">
          <div class="row  align-items-center mx-0">
            <div class="col-12 col-md-7  card-body  z-index-3">
              <h3 class="mb-1  p-2 p-md-4 text-bold nicole-text-title3">
                專案開始前
              </h3>   
              <ul class="nicole-text-time my-3 text-dark">
                <li class=" nicole-text-time pb-2">
                  透明化規格與報價
                </li>    
                <li class=" nicole-text-time pb-2">
                  透過訪談，將需求具體化，依據預期目標，評估專案規模、開發週期以及技術成本。
                </li>    
                <li class="nicole-text-time pb-2">
                  與客戶簽約並收取簽約款
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-4 ">
              <img
                src="@/assets/img/init.jpg"
                class="w-100 p-5 p-md-3 my-xl-6"
              >
            </div>
          </div>   
        </div>
      </div>
      <div
        class="mb-3 mb-md-5"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
        id="start"
      >
        <div class="card shadow-sm">
          <div class="row  align-items-center mx-0">
            <div class="col-12 col-md-6  px-0 ">
              <img
                src="@/assets/img/unitest2.png"
                class="w-100 obj-cov rounded-start-l d-md-block d-none"
                style="height: 347px;"
              >                             
              <img
                src="@/assets/img/unitest1.jpg"
                class="w-100 obj-cov rounded-start-l-top d-block d-md-none"
                style="height: 347px;"
              >                             
            </div>
            <div class="col-12 col-md-6 card-body  z-index-3 ">
              <h3 class="mb-1  p-2 p-md-4 text-bold nicole-text-title3">
                專案開始
              </h3>   
              <ul class="text-1816 my-3 text-dark">
                <li class="pb-2">
                  系統、功能、組織架構、時程等規畫並提供工作計劃書（SOW）
                </li> 
                <li class="pb-2">
                  Use Case/ UI /UX 確認。
                </li>
                <li class="pb-2">
                  根據規格明細與客戶討論提供驗收與測試計劃。
                </li>
              </ul>
            </div>
          </div>   
        </div>
      </div>
      <div
        class="mb-3 mb-md-5"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
        id="ing"
      >
        <div class="card shadow-sm">
          <div class="row  align-items-center mx-0">
            <div class="col-12 col-md-7 card-body  z-index-3">
              <h3 class="mb-1  p-2 p-md-4 text-bold nicole-text-title3">
                專案進行
              </h3>  
              <ul class="text-1816 my-3 text-dark">
                <li class="pb-2 text-bold">
		UT 測試階段
                </li>    
                <li class="pb-2">
                  敏捷開發 Scrum 追踪任務進度與問題狀態
                </li>    
                <li class="pb-2">
                  階段交付，即時處理issue
                </li>    
                <li class="pb-2">
                  品管部門進行單元測試（ UT：Unit Test ）及任務交付品質驗證
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-5 ">                         
              <img
                src="@/assets/img/exe1.jpg"
                class="w-100 p-5 "
              >                             
            </div>
          </div>   
        </div>
      </div>   
      <div
        class="mb-3 mb-md-5"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
        id="it"
      >
        <div class="card shadow-sm">
          <div class="row  align-items-center mx-0">
            <div class="col-12 col-md-6 px-0">
              <img
                src="@/assets/img/systest.png"
                class="w-100 obj-cov rounded-start-l d-md-block d-none"
                style="height: 347px;"
              >     
              <img
                src="@/assets/img/systest.jpg"
                class="w-100 obj-cov rounded-start-l-top d-block d-md-none"
                style="height: 347px;"
              > 
            </div>
            <div class="col-12 col-md-6 card-body  z-index-3">
              <h3 class="mb-1  p-2 p-md-4 text-bold nicole-text-title3">
                整合測試
              </h3>  
              <ul class="text-1816 my-3 text-dark">
                <li class="pb-2 text-bold">
		IT 測試階段
                </li>    
                <li class="pb-2">
                  系統工程師佈署整合自動化測試環境
                </li>    
                <li class="pb-2">
                  品管人員依 SOW 之 SLA 定義要求所有任務達到 Beta 以上品質
                </li>    
              </ul>
            </div>
          </div>   
        </div>
      </div>
      <div
        class="mb-3 mb-md-5"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
      >
        <div class="card shadow-sm">
          <div class="row  align-items-center mx-0">
            <div class="col-12 col-md-7 card-body  z-index-3">
              <h3 class="mb-1  p-2 p-md-4 text-bold nicole-text-title3">
                系統測試
              </h3>  
              <ul class="text-1816 my-3 text-dark">
                <li class="pb-2 text-bold">
		ST 測試階段
                </li>    
                <li class="pb-2">
                  系統工程師將 IT 階段開發軟體佈署到客戶指定之測試環境
                </li>    
                <li class="pb-2">
                  依 SOW 之  SLA 定義品質標準，解決所有範圍內之 BUG 與 問題
                </li>    
                <li class="pb-2">
                  與客戶核對規格明細點交功能後，收取階段性交付款並通知客戶進行驗收測試
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-5 position-relative">
              <img
                src="@/assets/img/cloudteest-02.jpg"
                class="w-100 p-5"
              >                             
            </div>
          </div>   
        </div>
      </div>   
      <div
        class="mb-3 mb-md-5"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
        id="test"
      >
        <div class="card shadow-sm">
          <div class="row  align-items-center mx-0">
            <div class="col-12 col-md-6 px-0">
              <img
                src="@/assets/img/test2.png"
                class="w-100 obj-cov rounded-start-l d-md-block d-none"
                style="height: 347px;"
              >        
              <img
                src="@/assets/img/test2.jpg"
                class="w-100 obj-cov rounded-start-l-top d-block d-md-none"
                style="height: 347px;"
              > 
            </div>
            <div class="col-12 col-md-6 card-body  z-index-3">
              <h3 class="mb-1  p-2 p-md-4 text-bold nicole-text-title3">
                驗收測試
              </h3>   
              <ul class="text-1816 my-3 text-dark">
                <li class="pb-2 text-bold">
		UAT 測試階段
                </li>    
                <li class="pb-2">
                  系統工程師將 IT 階段開發軟體佈署到客戶指定之測試環境
                </li> 
                <li class="pb-2">
                  請客戶根據驗收查核項目表及 SOW 之 SLA 規範進行驗收
                </li> 
                <li class="pb-2">
                  驗收無誤請客戶簽署驗收通過同意書
                </li>
                <li class="pb-2">
                  與客戶收取驗收尾款
                </li>
              </ul>
            </div>
          </div>   
        </div>
      </div>
      <div
        class="mb-3 mb-md-5"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
        id="close"
      >
        <div class="card shadow-sm">
          <div class="row  align-items-center mx-0">
            <div class="col-12 col-md-7 card-body  z-index-3">
              <h3 class="mb-1  p-2 p-md-4 text-bold nicole-text-title3">
                維運保固
              </h3>  
              <ul class="text-1816 my-3 text-dark">
                <li class="pb-2">
                  專人負責接收問題並發送 Support Ticket 給相關專業人員解決
                </li>    
                <li class="pb-2">
                  提供問題追踪系統，透明化您的問題被處理的狀態
                </li>    
                <li class="pb-2">
                  提供系統軟體正常運作保證服務
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-5 ">
              <img
                src="@/assets/img/begin.jpg"
                class="w-100 p-5 p-md-6"
              >                             
            </div>
          </div>   
        </div>
      </div> 
    </div>
  </div>

  <Footer />
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { oltConsole, oAA , oApiError } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , Footer } from '@/components/Frame';

export default {
  name: 'App',
  components: {
    SideNav,
    Footer
  },
  setup() {

    const copyrightTime = ref() ;

    //function ctime(){
    //  return new Date().getFullYear() ; 
    //}

    copyrightTime.value = new Date().getFullYear() ; 

    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      copyrightTime
    };
  },
  created() {
  },
  mounted() {
    this.$smoothScroll({
      scrollTo: document.getElementById('header'),
      //hash: '#header' // required if updateHistory is true
    });
  },
  methods: {
  }
};
</script>
